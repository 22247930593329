import React from 'react';
import { SvgIcon } from '@mui/material';

import stylesConstants from '../../styles/constants.module.scss';

type Props = {
  color: 'blue' | 'white' | 'black';
  thin?: boolean;
  className?: string;
};

function PlusIcon({ color, className, thin }: Props) {
  const Stroke = {
    blue: stylesConstants.BLUE_1,
    white: stylesConstants.WHITE,
    black: stylesConstants.BLACK,
  };

  return (
    <SvgIcon className={className} viewBox="0 0 28 28">
      <path
        d="M14 2L14 26M2 14H26"
        stroke={Stroke[color]}
        strokeWidth={thin ? 2 : 4}
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export { PlusIcon };
