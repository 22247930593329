import React, { useEffect, useRef } from 'react';

type Props = {
  strokeWidth?: number;
  animate?: boolean;
  size?: number;
  clickMode?: boolean;
};

export function DoodleMagnet({ strokeWidth = 1, animate = true, size = 140, clickMode }: Props) {
  const imageRef = useRef<SVGSVGElement>(null);
  const begin = clickMode ? 'magnet.click' : 0;
  const duration = '2s';
  const repeatCount = clickMode ? 1 : 'indefinite';
  useEffect(() => {
    imageRef.current?.[animate ? 'unpauseAnimations' : 'pauseAnimations']();
  }, [animate]);

  return (
    <svg
      ref={imageRef}
      id={clickMode ? 'magnet' : ''}
      width={size}
      height={size}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="magnet">
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -2 5; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
        <path
          d="M71.51 36.78C71.51 36.78 -2.73 71 40.61 103.72C67.61 121.72 100.77 81.24 107.44 70.72L99.08 61.18C99.08 61.18 68.69 99.37 52.38 90.09C33.26 79.21 73.75 48.75 80.89 44.73L71.51 36.78Z"
          fill="white"
        />
        <path d="M80.89 44.73L71.2 51.61L60.79 42.95L71.51 36.78L80.89 44.73Z" fill="#274AF2" />
        <path d="M107.44 70.7L101.38 78.88L91.45 70.06L99.08 61.16L107.44 70.7Z" fill="#274AF2" />
        <path
          d="M27.21 61.74C33.1802 61.74 38.02 56.9002 38.02 50.93C38.02 44.9598 33.1802 40.12 27.21 40.12C21.2398 40.12 16.4 44.9598 16.4 50.93C16.4 56.9002 21.2398 61.74 27.21 61.74Z"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.23 47.23C49.2002 47.23 54.04 42.3902 54.04 36.42C54.04 30.4498 49.2002 25.61 43.23 25.61C37.2598 25.61 32.42 30.4498 32.42 36.42C32.42 42.3902 37.2598 47.23 43.23 47.23Z"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71.51 36.78C71.51 36.78 -2.73 71 40.61 103.72C67.61 121.72 100.77 81.24 107.44 70.72L99.08 61.18C99.08 61.18 68.69 99.37 52.38 90.09C33.26 79.21 73.75 48.75 80.89 44.73L71.51 36.78Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M80.89 44.73L82.89 52.42C82.89 52.42 54.05 69.73 54.78 90.64"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M60.19 42.87L71.2 51.61L73.05 59.61"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M65.61 56.27L68.08 64.15"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M58.73 62.78L62.15 70.89"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M51.95 70.44L56.67 80.39"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M48.54 76.78L54.85 87.93"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M107.44 70.7L109.44 79.4C109.44 79.4 74.36 133.63 42.51 113.19C30.25 106.51 27.27 84.61 27.27 84.61"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M49.75 107.67L51.73 117.13"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M61.95 107.55L64.14 117.09"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M74.66 102.48L77.32 111.68"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M82.66 97.17L85.71 105.78"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M88.78 92.09L91.66 100.53"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M91.22 70.06L101.38 78.88L102.71 88.45"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M42.48 113.19L40.61 103.72"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
        />
        <path
          d="M31.7 54.84C34.4283 54.84 36.64 52.6283 36.64 49.9C36.64 47.1717 34.4283 44.96 31.7 44.96C28.9717 44.96 26.76 47.1717 26.76 49.9C26.76 52.6283 28.9717 54.84 31.7 54.84Z"
          fill="black"
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0, 0; -2 -2; 0, 0;"
            dur={duration}
            repeatCount={repeatCount}
            begin={begin}
          />
        </path>
        <path
          d="M47.42 40.29C50.1483 40.29 52.36 38.0783 52.36 35.35C52.36 32.6217 50.1483 30.41 47.42 30.41C44.6917 30.41 42.48 32.6217 42.48 35.35C42.48 38.0783 44.6917 40.29 47.42 40.29Z"
          fill="black"
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            values="0, 0; -2 -2; 0, 0;"
            dur={duration}
            repeatCount={repeatCount}
            begin={begin}
          />
        </path>
      </g>
      <path
        d="M85.37 18.39L81.78 30.41"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -1 -4; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M103.93 26.01L98.09 36.66"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -1 -4; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M119.86 40.65L110.21 48.35"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 2 4; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M131.64 56.57L118.19 58.52"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 2 2; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
    </svg>
  );
}
