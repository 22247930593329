import React from 'react';

type Props = {
  strokeWidth?: number;
  width?: number;
  height?: number;
};

export function DoodleDog({ width = 140, height = 140, strokeWidth = 1 }: Props) {
  const duration = '2s';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M68.2739 117.035C93.7287 117.035 114.364 96.9815 114.364 72.2447C114.364 47.5078 93.7287 27.4547 68.2739 27.4547C42.8191 27.4547 22.1839 47.5078 22.1839 72.2447C22.1839 96.9815 42.8191 117.035 68.2739 117.035Z"
        fill="#FFD45C"
      />
      <path
        d="M41.2039 108.495C41.2039 108.495 59.3739 123.605 85.8039 113.685C104.054 105.685 100.054 103.065 97.3439 84.3546C97.3439 84.3546 89.4039 60.1946 85.8039 57.5746C85.8039 57.5746 88.1439 45.0546 82.6939 43.1846C82.1439 42.9546 73.3339 50.9546 73.3339 50.9546L61.1139 50.6346C61.1139 50.6346 52.9539 38.9546 52.8039 42.1246C52.5039 52.1246 49.9439 54.5946 49.9439 54.5946C49.9439 54.5946 27.9439 68.8146 40.5939 88.5946L37.7439 105.805L41.2039 108.495Z"
        fill="white"
      />
      <path
        d="M68.2739 117.035C93.7287 117.035 114.364 96.9815 114.364 72.2447C114.364 47.5078 93.7287 27.4547 68.2739 27.4547C42.8191 27.4547 22.1839 47.5078 22.1839 72.2447C22.1839 96.9815 42.8191 117.035 68.2739 117.035Z"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.2745 27.4641C100.924 27.4641 119.724 47.5141 119.724 72.2441C119.724 96.9741 101.954 117.034 70.1945 117.034"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.204 45.4548L109.914 44.4248"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M110.384 54.0142L115.694 53.5142"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.504 63.5742H118.924"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114.374 73.8342H119.724"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M113.044 82.9547H118.554"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.684 95.4849H113.574"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.754 104.024L108.114 102.664"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.6741 39.8441C46.6741 39.8441 55.5941 31.8441 78.3741 34.1741"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.114 67.5448C108.114 67.5448 109.724 79.8248 105.204 90.1648"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="line"
        d="M23.6944 17.4442L33.4444 30.3142"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -4 -4; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M20.3843 31.2842L26.4243 35.7742"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -2 -7; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M115.694 107.314L126.424 114.724"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5 6; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M109.914 113.165L113.574 119.985"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 6 1; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M111.744 23.8842L106.804 28.9442"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 6 -1; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M122.874 23.8845L110.624 32.3745"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 6 -4; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M25.48 106L13 113.41"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -3 6; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        className="line"
        d="M27.21 112L23 116.91"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 2 -1; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M67.7543 64.9547C67.7543 64.9547 66.0143 71.7547 61.1043 71.7047C56.1943 71.6547 54.6243 64.0347 54.6243 64.0347L51.1643 63.8247C51.1643 63.8247 51.1643 70.5547 47.2343 70.4247C43.3043 70.2947 43.0443 68.1047 42.2343 60.7747L81.0043 60.9747L81.1243 64.0747L67.7543 64.9547Z"
        fill="black"
      />
      <path
        d="M44.0143 73.8548C43.9882 73.2403 44.1635 72.6342 44.5138 72.1287C44.864 71.6232 45.3699 71.2461 45.9543 71.0547C47.0315 70.7624 48.1671 70.7624 49.2443 71.0547C53.2443 72.0547 52.6643 73.4347 51.9343 74.5947C51.2043 75.7547 48.1543 78.3648 46.9343 78.4948C45.7143 78.6248 44.1043 76.9548 44.0143 73.8548Z"
        fill="black"
      />
      <path
        d="M44.2742 81.0944C44.2742 81.0944 47.0442 79.0944 49.6242 80.9844C51.9542 82.6444 53.1742 83.0444 55.4842 83.0444C57.7942 83.0444 61.0942 81.2344 61.0942 81.2344"
        stroke="black"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M84.3741 68.8141C85.1409 74.4057 83.8664 80.0863 80.7841 84.8141C73.4041 95.6841 48.2441 104.814 39.1641 86.0641C30.2041 67.5341 49.9541 54.5941 49.9541 54.5941C51.9541 52.8841 52.4441 45.7941 52.5441 42.7941C52.5447 42.6109 52.5911 42.4307 52.679 42.2699C52.767 42.1092 52.8937 41.9729 53.0477 41.8736C53.2017 41.7742 53.3781 41.7149 53.5608 41.701C53.7435 41.6872 53.9268 41.7192 54.094 41.7941C55.5447 42.5241 56.6713 43.7684 57.254 45.2841C59.254 49.9141 61.434 50.7341 63.364 50.7941C65.294 50.8541 73.3641 50.7941 74.1141 50.5941C75.3241 50.2841 80.5041 43.0641 82.7041 43.1441C84.9041 43.2241 88.704 48.8741 85.044 59.4941"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M85.8042 56.9543C85.8042 56.9543 103.144 86.3443 98.8842 105.694"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.594 88.5844C40.594 88.5844 38.504 101.644 37.744 105.794"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M83.9541 43.7539C83.9541 43.7539 77.6141 51.0239 78.9541 54.5439"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
