import React from 'react';
import { SvgIcon } from '@mui/material';
import cn from 'classnames';

import styles from './BybitLogo.module.scss';

type Props = {
  size: 'large' | 'small';
  isColorful?: boolean;
};

function BybitLogo({ isColorful = true, size }: Props) {
  const mainColor = isColorful ? '#15192A' : '#BFBFBF';
  return (
    <SvgIcon className={cn(styles.root, styles[size])} viewBox="0 0 87 34">
      <path
        d="M62.0083 25.3572V3H66.5022V25.3572H62.0083Z"
        fill={isColorful ? '#F7A600' : '#BFBFBF'}
      />
      <path
        d="M9.63407 31.9978H0V9.64062H9.24666C13.7406 9.64062 16.3591 12.0898 16.3591 15.9209C16.3591 18.4008 14.6774 20.0034 13.5134 20.537C14.9028 21.1647 16.6813 22.5774 16.6813 25.5619C16.6813 29.7368 13.7406 31.9978 9.63407 31.9978ZM8.89096 13.535H4.4939V18.6847H8.89096C10.7981 18.6847 11.8652 17.6483 11.8652 16.109C11.8652 14.5714 10.7981 13.535 8.89096 13.535ZM9.18151 22.6099H4.4939V28.1051H9.18151C11.2189 28.1051 12.1874 26.8498 12.1874 25.3413C12.1874 23.8345 11.2171 22.6099 9.18151 22.6099Z"
        fill={mainColor}
      />
      <path
        d="M30.3882 22.8288V31.9978H25.926V22.8288L19.0073 9.64062H23.8886L28.1888 18.6522L32.4239 9.64062H37.3052L30.3882 22.8288Z"
        fill={mainColor}
      />
      <path
        d="M50.0457 31.9978H40.4116V9.64062H49.6583C54.1522 9.64062 56.7707 12.0898 56.7707 15.9209C56.7707 18.4008 55.089 20.0034 53.925 20.537C55.3144 21.1647 57.093 22.5774 57.093 25.5619C57.093 29.7368 54.1522 31.9978 50.0457 31.9978ZM49.3026 13.535H44.9055V18.6847H49.3026C51.2097 18.6847 52.2768 17.6483 52.2768 16.109C52.2768 14.5714 51.2097 13.535 49.3026 13.535ZM49.5931 22.6099H44.9055V28.1051H49.5931C51.6305 28.1051 52.599 26.8498 52.599 25.3413C52.599 23.8345 51.6305 22.6099 49.5931 22.6099Z"
        fill={mainColor}
      />
      <path
        d="M80.986 13.535V31.9995H76.4921V13.535H70.4785V9.64062H86.9996V13.535H80.986Z"
        fill={mainColor}
      />
    </SvgIcon>
  );
}

export { BybitLogo };
