import React, { useState } from "react";
import cn from "classnames";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import { SocialLinks } from "../SocialLinks/SocialLinks";
import { devices } from "../../styles/devices";

import { Link, LinkColor } from "../Link/Link";
import { MinusIcon, PlusIcon } from "../icons";
import { Container } from "../Container/Container";
import styles from "./Footer.module.scss";
import { FakeLink } from "../FakeLink/FakeLink";

type NavItem = {
  label: string;
  isSubItem?: boolean;
};

const sortNavItemByLabel = (a: NavItem, b: NavItem) => {
  if (a.label < b.label) {
    return -1;
  }
  if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const Footer = () => {
  const [expandedItems, setExpandedItems] = useState<{
    [key: number]: boolean;
  }>({});

  const accordionClasses = {
    root: styles.accordionSummary,
    content: styles.accordionSummaryContent,
    expandIconWrapper: styles.expandIconWrapper,
  };

  const platformLinks: NavItem[] = [
    {
      label: "Trading bots",
    },
    {
      label: "GRID bot",
      isSubItem: true,
    },
    {
      label: "DCA bot",
      isSubItem: true,
    },
    {
      label: "Futures bot",
      isSubItem: true,
    },
    {
      label: "Smart trading",
    },
    {
      label: "Demo trading",
    },
    {
      label: "Pricing",
    },
  ];

  const companyLinks: NavItem[] = [
    {
      label: "About us",
    },
    {
      label: "Security",
    },
    {
      label: "Affiliate program",
    },
    {
      label: "Affiliate competition",
    },
    {
      label: "Careers",
    },
    {
      label: "Contact info",
    },
  ];

  const resourcesLinks: NavItem[] = [
    {
      label: "Blog",
    },
    {
      label: "Knowledge base",
    },
    {
      label: "Exchanges",
    },
  ];

  const legalLinks: NavItem[] = [
    {
      label: "Terms & Conditions",
    },
    {
      label: "Privacy policy",
    },
    {
      label: "Refund policy",
    },
    {
      label: "Cookie policy",
    },
    {
      label: "Affiliate policy",
    },
  ];

  const exchangeLinks: NavItem[] = [
    {
      label: "Coinbase Pro",
    },
    {
      label: "Huobi",
    },
    {
      label: "Kucoin",
    },
    {
      label: "OKX",
    },
    {
      label: "Bybit",
    },
    {
      label: "Binance",
    },
    {
      label: "Bittrex",
    },
    {
      label: "Bitfinex",
    },
    {
      label: "Kraken",
    },
    {
      label: "HitBTC",
    },
    {
      label: "Poloniex",
    },
    {
      label: "Gemini",
    },
    {
      label: "Gate.io",
    },
    {
      label: "WhiteBIT",
    },
  ].sort(sortNavItemByLabel);

  const coinLinks: NavItem[] = [
    {
      label: "Bitcoin",
    },
    {
      label: "Tether",
    },
    {
      label: "Ripple",
    },
    {
      label: "ApeCoin",
    },
    {
      label: "Solana",
    },
    {
      label: "Dogecoin",
    },
    {
      label: "Ethereum",
    },
    {
      label: "Shiba Inu",
    },
    {
      label: "Polygon",
    },
    {
      label: "BNB",
    },
    {
      label: "Cardano",
    },
    {
      label: "Polkadot",
    },
    {
      label: "Dai",
    },
    {
      label: "Tron",
    },
    {
      label: "Binance USD",
    },
    {
      label: "Avalanche",
    },
    {
      label: "USDС",
    },
    {
      label: "Ethereum Classic",
    },
    {
      label: "Uniswap",
    },
    {
      label: "LEO",
    },
    {
      label: "Chainlink",
    },
    {
      label: "Cosmos",
    },
    {
      label: "NEAR",
    },
    {
      label: "Cronos",
    },
    {
      label: "Algorand",
    },
    {
      label: "Bitcoin Cash",
    },
    {
      label: "Flow",
    },
    {
      label: "Aave",
    },
    {
      label: "Axie",
    },
    {
      label: "Decentraland",
    },
    {
      label: "IPChain",
    },
    {
      label: "KuCoin Token",
    },
    {
      label: "Litecoin",
    },
    {
      label: "Sandbox",
    },
    {
      label: "VeChain",
    },
  ].sort(sortNavItemByLabel);

  return (
    <footer className={cn(styles.root, styles["grey"])}>
      <div className={devices.mobile}>
        {renderMainInfo()}
        {renderNavAccordion(0, "Platform", platformLinks)}
        {renderNavAccordion(1, "Company", companyLinks)}
        {renderNavAccordion(2, "Resources", resourcesLinks)}
        {renderNavAccordion(3, "Legal", legalLinks)}
        <div className={styles.supportedExchanges}>
          {renderNavAccordion(4, "Supported Exchanges", exchangeLinks)}
        </div>
        <div className={styles.supportedCoins}>
          {renderNavAccordion(5, "Supported Coins", coinLinks)}
        </div>
      </div>
      <div className={devices.tabletDesktop}>
        <Container>
          <div className={styles.mainContent}>
            {renderMainInfo()}
            <nav className={styles.navigation}>
              {renderNavList("Platform", platformLinks)}
              {renderNavList("Company", companyLinks)}
              {renderNavList("Resources", resourcesLinks)}
              {renderNavList("Legal", legalLinks)}
            </nav>
          </div>
        </Container>
        <div className={cn(styles.quickLinksWrapper, styles["grey"])}>
          <Container>
            <div className={styles.supportedExchanges}>
              {renderQuickLinks("Supported Exchanges", exchangeLinks)}
            </div>
            <div className={styles.supportedCoins}>
              {renderQuickLinks("Supported Coins", coinLinks)}
            </div>
          </Container>
        </div>
      </div>
    </footer>
  );

  function renderNavAccordion(index: number, title: string, links: NavItem[]) {
    return (
      <Accordion
        onChange={(_, expanded) =>
          setExpandedItems((items) => ({ ...items, [index]: expanded }))
        }
        disableGutters
        classes={{ root: styles.accordionRoot }}
        square
      >
        <AccordionSummary
          classes={accordionClasses}
          expandIcon={
            expandedItems[index] ? (
              <MinusIcon className={styles.expandIcon} />
            ) : (
              <PlusIcon className={styles.expandIcon} color="blue" />
            )
          }
        >
          {title}
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.accordionDetails }}>
          <ul className={styles.navLinks}>
            {links.map((link) => (
              <li
                key={link.label}
                className={cn(styles.navLink, {
                  [styles.withIndent]: link.isSubItem,
                })}
              >
                <Link color={LinkColor.BLACK}>{link.label}</Link>
              </li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    );
  }

  function renderNavList(
    title: string,
    links: NavItem[],
    addition?: JSX.Element
  ) {
    return (
      <div className={styles.navList}>
        <h5 className={cn(styles.navListItem, styles.bold)}>{title}</h5>
        <ul className={styles.navListLinks}>
          {links.map((link) => (
            <li
              key={link.label}
              className={cn(styles.navListItem, {
                [styles.withIndent]: link.isSubItem,
              })}
            >
              <Link color={LinkColor.BLACK}>{link.label}</Link>
            </li>
          ))}
          {addition && <li className={styles.navListItem}>{addition}</li>}
        </ul>
      </div>
    );
  }

  function renderMainInfo() {
    return (
      <div>
        <p className={styles.copyright}>
          2023. Bitsgap Holding OU
          <br /> Luminori maja Liivalaia 45
          <br /> 10145, Tallinn, Estonia
        </p>
        <div className={styles.socialMedia}>
          <SocialLinks />
        </div>
      </div>
    );
  }

  function renderQuickLinks(title: string, links: NavItem[]) {
    return (
      <div className={styles.quickLinks}>
        <span>{title}</span>
        {links.map((link) => (
          <FakeLink key={link.label}>
            <a className={cn(styles.quickLink, styles["white"])}>
              {link.label}
            </a>
          </FakeLink>
        ))}
      </div>
    );
  }
};
