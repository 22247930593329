import React from 'react';
import cn from 'classnames';

import styles from './ThemeSwitcher.module.scss';

type Props = {
  theme: 'light' | 'dark';
};

const ThemeSwitcher = ({ theme }: Props) => {
  const isLight = theme === 'light';

  return (
    <svg
      className={cn(styles.root, styles[theme])}
      width="85"
      height="40"
      viewBox="0 0 85 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className={styles.background} opacity="0.16">
        <rect width="85" height="40" rx="8" fill="black" />
      </g>
      <rect className={styles.border} x="0.5" y="0.5" width="84" height="39" rx="7.5" />

      {isLight ? (
        <rect x="2" y="2" width="36" height="36" rx="6" fill="white" />
      ) : (
        <rect className={styles.moonBackground} x="47" y="2" width="36" height="36" rx="6" />
      )}
      <path
        className={styles.moon}
        d="M74.0009 20.79C73.8436 22.4922 73.2047 24.1144 72.1591 25.4668C71.1135 26.8192 69.7044 27.8458 68.0966 28.4265C66.4888 29.0073 64.7489 29.1181 63.0804 28.7461C61.4119 28.3741 59.8839 27.5345 58.6751 26.3258C57.4663 25.117 56.6268 23.589 56.2548 21.9205C55.8828 20.252 55.9936 18.5121 56.5743 16.9043C57.1551 15.2965 58.1817 13.8874 59.5341 12.8418C60.8865 11.7961 62.5087 11.1573 64.2109 11C63.2143 12.3483 62.7347 14.0094 62.8594 15.6814C62.9841 17.3534 63.7047 18.9251 64.8903 20.1106C66.0758 21.2961 67.6475 22.0168 69.3195 22.1415C72.6898 22.3928 74.0009 20.79 74.0009 20.79Z"
      />
      <path
        className={styles.sun}
        d="M20 25C22.7614 25 25 22.7614 25 20C25 17.2386 22.7614 15 20 15C17.2386 15 15 17.2386 15 20C15 22.7614 17.2386 25 20 25Z"
      />
      <path
        className={styles.sunray}
        d="M20 9V11"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M20 29V31"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M12.2207 12.2188L13.6407 13.6388"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M26.3594 26.3599L27.7794 27.7799"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M9 20H11"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M29 20H31"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M12.2207 27.7799L13.6407 26.3599"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className={styles.sunray}
        d="M26.3594 13.6388L27.7794 12.2188"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export { ThemeSwitcher };
