import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  styled,
  Tabs,
} from "@mui/material";
import MuiTab from "@mui/material/Tab";
import cn from "classnames";
import React, { useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import DarkTheme from "../../images/home/home-features/theme/dark-theme.svg";
import DarkThemeDesktop from "../../images/home/home-features/theme/dark-theme_desktop.svg";
import DarkThemeTablet from "../../images/home/home-features/theme/dark-theme_tablet.svg";
import LightTheme from "../../images/home/home-features/theme/light-theme.svg";
import LightThemeDesktop from "../../images/home/home-features/theme/light-theme_desktop.svg";
import LightThemeTablet from "../../images/home/home-features/theme/light-theme_tablet.svg";
import SmartOrdersCard_1 from "../../images/home/home-features/smart-orders/smart-orders_01.png";
import SmartOrdersCard_2 from "../../images/home/home-features/smart-orders/smart-orders_02.png";
import SmartOrdersCard_3 from "../../images/home/home-features/smart-orders/smart-orders_03.png";
import TradingBotsCard_1 from "../../images/home/home-features/trading-bots/trading-bots_01.svg";
import TradingBotsCard_2 from "../../images/home/home-features/trading-bots/trading-bots_02.svg";
import TradingBotsCard_3 from "../../images/home/home-features/trading-bots/trading-bots_03.svg";
// import { Button, Container, MinusIcon, PlusIcon, ThemeSwitcher } from 'shared';
import { ButtonVariant, ButtonSize } from "../../styles/theme";
import { devices, deviceTypes } from "../../styles/devices";

import { TrustBox, TrustBoxParams } from "../TrustBox/TrustBox";
import styles from "./HomeFeatures.module.scss";
import { DoodleDog, DoodleEthereum, Doodles, DoodleType } from "../Doodle";
import { Container } from "../Container/Container";
import { usePreloadImages } from "../../helpers/hooks/usePreloadImages";
import { Button } from "../Button/Button";
import { MinusIcon, PlusIcon } from "../icons";
import { ThemeSwitcher } from "../ThemeSwitcher/ThemeSwitcher";
import { FakeLink } from "../FakeLink/FakeLink";

const imageDir = JSON.parse(styles.IMAGE_DIR);

const doodles: DoodleType[] = [
  {
    className: styles.doodleDog,
    Doodle: DoodleDog,
  },
  {
    className: styles.doodleEthereum,
    Doodle: DoodleEthereum,
  },
];

enum Feature {
  BOTS = "tradingBots",
  ORDERS = "smartOrders",
  TERMINAL = "tradingTerminal",
}

enum THEME {
  LIGHT = "light",
  DARK = "dark",
}

const ThemeImage = {
  mobile: {
    [THEME.LIGHT]: LightTheme,
    [THEME.DARK]: DarkTheme,
    width: 777,
    height: 378,
  },
  tablet: {
    [THEME.LIGHT]: LightThemeTablet,
    [THEME.DARK]: DarkThemeTablet,
    width: 705,
    height: 344,
  },
  desktop: {
    [THEME.LIGHT]: LightThemeDesktop,
    [THEME.DARK]: DarkThemeDesktop,
    width: 777,
    height: 378,
  },
};

const CardImage = {
  [Feature.BOTS]: [TradingBotsCard_1, TradingBotsCard_2, TradingBotsCard_3],
  [Feature.ORDERS]: [SmartOrdersCard_1, SmartOrdersCard_2, SmartOrdersCard_3],
};

const Tab = styled(MuiTab)(() => ({
  "&.Mui-focusVisible": {
    boxShadow: "inset 0 0 0 1px #523AFD",
  },
}));

const mobileImagesToPreload: string[] = [
  `${imageDir}/theme/light-theme.svg`,
  `${imageDir}/theme/dark-theme.svg`,
];

const tabletImagesToPreload: string[] = [
  `${imageDir}/theme/light-theme_tablet.svg`,
  `${imageDir}/theme/dark-theme_tablet.svg`,
];

const desktopImagesToPreload: string[] = [
  `${imageDir}/theme/light-theme_desktop.svg`,
  `${imageDir}/theme/dark-theme_desktop.svg`,
];

const allDevicesImagesToPreload: string[] = [
  `${imageDir}/smart-orders/smart-orders_01.png`,
  `${imageDir}/smart-orders/smart-orders_02.png`,
  `${imageDir}/smart-orders/smart-orders_03.png`,
  `${imageDir}/trading-bots/trading-bots_01.svg`,
  `${imageDir}/trading-bots/trading-bots_02.svg`,
  `${imageDir}/trading-bots/trading-bots_03.svg`,
];

type HomeFeaturesProps = {
  trustBoxParams: TrustBoxParams;
};

const featuresTexts = {
  tradingBots: {
    label: "Trading bots",
    labelWithWrap: (
      <>
        Trading
        <br />
        bots
      </>
    ),
    mainText:
      "Profit from every market move 24/7, with fully automated trading bots.",
    text: "Set up effective back-tested GRID and DCA strategies in a few minutes or build your custom strategies.",
    gridBot: "GRID Bot",
    scalperBot: "Scalper Bot",
    DCABot: "DCA Bot",
  },
  smartOrders: {
    label: "Smart orders",
    labelWithWrap: (
      <>
        Smart
        <br />
        orders
      </>
    ),
    mainText:
      "Execute your trading strategy with a set of powerful algorithmic orders.",
    text: "Control your risk/reward ratio with tools your exchange doesn’t offer like Stop Loss, Take Profit, Trailing Take Profit, and OCO for all your Limit, Market, Stop, Stop Limit, TWAP, and Scaled orders.",
  },
  tradingTerminal: {
    label: "Trading terminal",
    labelWithWrap: (
      <>
        Trading
        <br />
        terminal
      </>
    ),
    mainText: "Monitor. Switch. Trade. Repeat.",
    text: "Instantly switch between exchanges in an easy-to-use unified interface and trade without limits with our advanced tools.",
  },
};

export const HomeFeatures = ({ trustBoxParams }: HomeFeaturesProps) => {
  const [tabValue, setTab] = useState(0);
  const [expandedItems, setExpandedItems] = useState<{
    [key: number]: boolean;
  }>({});
  const [theme, setTheme] = useState(THEME.LIGHT);
  const observeFeatureDetailsInViewport = usePreloadImages({
    mobile: mobileImagesToPreload,
    tablet: tabletImagesToPreload,
    desktop: desktopImagesToPreload,
    allDevices: allDevicesImagesToPreload,
  });

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const handleThemeChange = () => {
    const newTheme = theme === THEME.LIGHT ? THEME.DARK : THEME.LIGHT;
    setTheme(newTheme);
  };

  const classes = {
    root: styles.accordionSummary,
    content: styles.accordionSummaryContent,
    expandIconWrapper: styles.expandIconWrapper,
  };

  const features = [Feature.BOTS, Feature.ORDERS, Feature.TERMINAL];

  return (
    <section className={styles.root} data-test="home-features">
      <Container centered>
        <div className={styles.trustBox}>
          <TrustBox {...trustBoxParams} />
        </div>
        <h2 className={styles.title}>
          Watch your cryptocurrency investments grow
        </h2>
        <p className={styles.description}>
          From powerful trading bots to smart orders, Bitsgap gives you
          an advantage in the fast-changing world of crypto trading.
        </p>
      </Container>

      <div className={devices.mobileTablet}>{renderAccordion()}</div>
      {renderTabs()}
      <Doodles doodles={doodles} />
    </section>
  );

  function getFeatureLabel(featureName: Feature) {
    return (
      <h3 className={styles.tabLabel}>
        <span className={devices.mobileTablet}>
          {featuresTexts[featureName].label}
        </span>
        <span className={devices.desktop}>
          {featuresTexts[featureName].labelWithWrap}
        </span>
      </h3>
    );
  }

  function getImageAlt(featureName: Feature) {
    return `${featuresTexts[featureName].label} example card`;
  }

  function renderImage(featureName: Feature) {
    if (featureName === Feature.TERMINAL) {
      return (
        <>
          <div className={cn(styles.imageWrapper, styles.terminal)}>
            <div className={styles.themeImage}>
              {deviceTypes.map((deviceType) => (
                <span className={devices[deviceType]} key={deviceType}>
                  <img
                    className={devices[deviceType]}
                    src={ThemeImage[deviceType][theme]}
                    width={ThemeImage[deviceType].width}
                    height={ThemeImage[deviceType].height}
                    alt={getImageAlt(featureName)}
                  />
                </span>
              ))}
            </div>
          </div>
          <IconButton
            className={styles.themeSwitcher}
            onClick={handleThemeChange}
          >
            <ThemeSwitcher theme={theme} />
          </IconButton>
        </>
      );
    }

    return (
      <div className={styles.imageWrapper}>
        {CardImage[featureName].map((image, index) => {
          return (
            <div key={`${featureName}-${index}`} className={styles.imageCard}>
              <img
                src={image}
                width={365}
                height={106}
                alt={getImageAlt(featureName)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  function renderFeatureDetails(featureName: Feature) {
    return (
      <div
        className={cn(styles.featureDetails, styles[featureName])}
        ref={observeFeatureDetailsInViewport}
      >
        <Container>
          <p className={styles.mainText}>
            {featuresTexts[featureName].mainText}
          </p>
          <p className={styles.text}>{featuresTexts[featureName].text}</p>
          {renderImage(featureName)}
          <div className={styles.moreButton}>
            <FakeLink>
              <Button size={ButtonSize.SMALL} variant={ButtonVariant.CONTAINED}>
                Learn more
                <ArrowForwardIcon className={styles.arrowIcon} />
              </Button>
            </FakeLink>
          </div>
        </Container>
      </div>
    );
  }

  function renderAccordion() {
    return (
      <>
        {features.map((feature, i) => {
          return (
            <Accordion
              onChange={(_, expanded) =>
                setExpandedItems((items) => ({ ...items, [i]: expanded }))
              }
              disableGutters
              classes={{
                root: cn(styles.accordionRoot, {
                  [styles.expanded]: expandedItems[i],
                }),
              }}
              square
              key={i}
            >
              <AccordionSummary
                id={`accordion-summary-${i}`}
                classes={classes}
                expandIcon={
                  expandedItems[i] ? (
                    <MinusIcon className={styles.expandIcon} />
                  ) : (
                    <PlusIcon className={styles.expandIcon} color="white" />
                  )
                }
              >
                {getFeatureLabel(feature)}
              </AccordionSummary>
              <AccordionDetails classes={{ root: styles.accordionDetails }}>
                {renderFeatureDetails(feature)}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    );
  }

  function renderTab(feature: Feature, index: number) {
    return (
      <Tab
        key={feature}
        id={`vertical-tab-${index}`}
        className={cn(styles.tab, tabValue === index ? styles.selected : '')}
        label={getFeatureLabel(feature)}
        disableRipple
      />
    );
  }

  function renderTabPanel(feature: Feature, index: number) {
    return (
      <div
        key={feature}
        className={cn(styles.tabPanel, styles[feature])}
        hidden={tabValue !== index}
        id={`vertical-tabpanel-${index}`}
      >
        {tabValue === index && renderFeatureDetails(feature)}
      </div>
    );
  }

  function renderTabs() {
    return (
      <Container centered>
        <div className={styles.tabsWrapper}>
          <Tabs
            className={styles.tabs}
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="Feature tabs"
            sx={{ borderRight: 1, borderColor: "divider" }}
            classes={{ indicator: styles.indicator }}
          >
            {features.map((feature, index) => renderTab(feature, index))}
          </Tabs>
          {features.map((feature, index) => renderTabPanel(feature, index))}
        </div>
      </Container>
    );
  }
};
