import React, { ReactNode } from "react";
import cn from "classnames";

import styles from "./Marker.module.scss";

export enum MarkerHeading {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
}

type Props = {
  heading: MarkerHeading;
  children?: ReactNode;
};

export const Marker = ({ children, heading }: Props) => {
  return (
    <span className={cn(styles.root, styles[heading])}>
      {children}
      <span className={styles.bg} />
    </span>
  );
};
