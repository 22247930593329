export function getPlatformNavLinks(): string[] {
    return ["Trading bots", "Smart trading", "Demo trading"];
}

export function getCompanyNavLinks(): string[] {
    return ["About us", "Security", "Affiliate program", "Contact info"];
}

export function getResourcesNavLinks(): string[] {
    return ["Blog", "Knowledge base", "Exchanges"];
}