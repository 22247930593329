import React from "react";

type Props = {
  children: React.ReactNode;
};
export const FakeLink = ({ children }: Props) => {
  return <span onClick={handleClick}>{children}</span>;

  function handleClick(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    window.open("https://bitsgap.com", "_self");
  }
};
