import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  SwipeableDrawer,
} from "@mui/material";
import cn from "classnames";

import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import { devices } from "../../../styles/devices";

import { MinusIcon, PlusIcon } from "../../icons";
import { HeaderLogo } from "../HeaderLogo/HeaderLogo";
import { FakeLink } from "../../FakeLink/FakeLink";
import styles from "./HeaderMainMenu.module.scss";
import {
  getCompanyNavLinks,
  getPlatformNavLinks,
  getResourcesNavLinks,
} from "../helper";
import { AuthButtons } from '../../AuthButtons/AuthButtons';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
};

export const HeaderMainMenu = ({ isOpen, onClose, onOpen }: Props) => {
  const [expandedNavAccordions, setExpandedNavAccordions] = useState<{
    [key: number]: boolean;
  }>({});

  return (
    <>
      <Dialog
        classes={{ root: devices.mobile }}
        fullScreen
        transitionDuration={0}
        open={isOpen}
        onClose={handleMenuClose}
      >
        {renderContent()}
      </Dialog>
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        SlideProps={{
          onExited: handleMenuClose,
        }}
        onClose={onClose}
        onOpen={onOpen}
        classes={{
          root: devices.tablet,
          paper: styles.drawerPaper,
        }}
        ModalProps={{
          BackdropProps: {
            className: styles.backdrop,
          },
        }}
      >
        {renderContent()}
      </SwipeableDrawer>
    </>
  );

  function renderContent() {
    return (
      <>
        <div className={styles.menuHeader}>
          <div className={cn(devices.mobile, styles.menuLogo)}>
            <HeaderLogo />
          </div>
          <button
            className={styles.languageExpandButton}
          >

          </button>
          <div className={styles.closeButtonWrapper}>
            <button className={styles.closeButton} onClick={handleMenuClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div
          className={styles.menuNavigation}
        >
          {renderNavAccordion(0, "Platform", getPlatformNavLinks())}
          {renderNavAccordion(1, "Company", getCompanyNavLinks())}
          {renderNavAccordion(2, "Resources", getResourcesNavLinks())}
          <FakeLink>
            <a className={styles.navButton}>Pricing</a>
          </FakeLink>
        </div>
        <AuthButtons />
      </>
    );
  }
  function renderNavAccordion(index: number, title: string, links: string[]) {
    const isAccordionOpened = expandedNavAccordions[index];
    const accordionSummaryClasses = {
      root: cn(styles.accordionSummary, {
        [styles.withoutBorder]: index === 0,
      }),
      content: cn(styles.accordionSummaryContent, {
        [styles.highlighted]: isAccordionOpened,
      }),
      expandIconWrapper: styles.expandIconWrapper,
    };

    return (
      <Accordion
        onChange={(_, expanded) =>
          setExpandedNavAccordions((items) => ({ ...items, [index]: expanded }))
        }
        disableGutters
        classes={{ root: styles.accordionRoot }}
        square
      >
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={
            isAccordionOpened ? (
              <MinusIcon className={styles.expandIcon} thin />
            ) : (
              <PlusIcon className={styles.expandIcon} color="black" thin />
            )
          }
        >
          {title}
        </AccordionSummary>
        <AccordionDetails classes={{ root: styles.accordionDetails }}>
          <ul className={styles.navLinks}>{links.map(renderLinkItem)}</ul>
        </AccordionDetails>
      </Accordion>
    );
  }

  function renderLinkItem(link: string) {
    return (
      <li key={link}>
        <FakeLink>
          <a className={styles.navLink}>{link}</a>
        </FakeLink>
      </li>
    );
  }

  function handleMenuClose() {
    onClose();
    setExpandedNavAccordions({});
  }
};
