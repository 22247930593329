import React from "react";

import styles from "./SocialLinks.module.scss";
import { Link, LinkColor } from "../Link/Link";

type Props = {
  isTelegram?: boolean;
};
const SOCIAL_LINKS = [
  "Telegram",
  "Youtube",
  "Facebook",
  "Twitter",
  "Instagram",
  "Discord",
];
export const SocialLinks = ({ isTelegram }: Props) => {
  return (
    <>
      {SOCIAL_LINKS.map((label) => (
        <span key={label} className={styles.link}>
          <Link color={LinkColor.BLUE}>
            <span className={styles.label}>{label}</span>
          </Link>
        </span>
      ))}
    </>
  );
};
