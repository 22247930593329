import React from 'react';
import cn from 'classnames';

import type { DeviceClass } from '../../styles/devices';

import styles from './Container.module.scss';

type Props = {
  centered?: boolean;
  fullHeight?: boolean;
  devices?: DeviceClass;
  children: React.ReactNode;
};

export const Container = ({ centered, children, fullHeight, devices }: Props) => (
  <div
    className={cn(styles.root, devices, {
      [styles.centered]: centered,
      [styles.fullHeight]: fullHeight,
    })}
  >
    {children}
  </div>
);
