import React, { FC } from "react";
import cn from "classnames";

import styles from "./Link.module.scss";
import { FakeLink } from "../FakeLink/FakeLink";

export enum LinkColor {
  BLUE = "blue",
  BLACK = "black",
}

type Props = {
  children?: React.ReactNode;
  color?: LinkColor;
};

export const Link: FC<Props> = (props) => {
  const { color, children } = props;
  const className = cn(styles.root, {
    [styles[color as LinkColor]]: color,
  });

  return (
    <FakeLink>
      <a className={className}>{children}</a>
    </FakeLink>
  );
};
