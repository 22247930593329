import React, { useEffect, useRef } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

import { ReviewCard, ReviewCardProps } from "./ReviewCard/ReviewCard";
import styles from "./ReviewsList.module.scss";

type ReviewType = "media" | "people";
type Review = {
  content: ReviewCardProps;
  id: string;
  type?: ReviewType;
};

type Props = {
  list: Review[];
};

export const ReviewsList = ({ list }: Props) => {
  const scrollContainer = useRef<HTMLElement>(null);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({ left: 0, behavior: "smooth" });
    }
  }, [list]);

  return (
    <ScrollContainer
      innerRef={scrollContainer}
      className={styles.root}
      hideScrollbars={false}
      vertical={false}
    >
      <div className={styles.reviewsList}>
        {list.map((review) => (
          <div className={styles.cardWrapper} key={review.id}>
            <ReviewCard {...review.content} />
          </div>
        ))}
      </div>
    </ScrollContainer>
  );
};

export type { Review as ReviewProps, ReviewType };
