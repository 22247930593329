import React from 'react';


import { Eyes } from './Eyes';
import { devices } from '../../../styles/devices';

type Props = {
  className: string;
  flipHorizontal?: boolean;
  viewDirection?: 'left' | 'right';
  withEyeBrows?: boolean;
};

export function DoodleEyes({
  className,
  flipHorizontal = false,
  viewDirection = 'right',
  withEyeBrows = false,
}: Props) {
  return (
    <div className={className}>
      <div className={devices.mobileTablet}>
        <Eyes
          strokeWidth={1}
          flipHorizontal={flipHorizontal}
          viewDirection={viewDirection}
          withEyeBrows={withEyeBrows}
        />
      </div>
      <div className={devices.desktop}>
        <Eyes
          strokeWidth={2}
          isFollowing={true}
          flipHorizontal={flipHorizontal}
          withEyeBrows={withEyeBrows}
        />
      </div>
    </div>
  );
}
