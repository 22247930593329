import React from 'react';

type Props = {
  strokeWidth?: number;
};

export function DoodleBear({ strokeWidth = 1 }: Props) {
  const duration = '2s';

  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M65.66 39.54C65.66 39.54 50.85 28.91 63.25 20.76C75.65 12.61 81.68 27.49 78.84 35.28C78.84 35.28 92.66 34.22 96.91 37.06C96.91 37.06 95.21 16.74 111.6 21.28C127.99 25.82 119.1 44.18 108.6 45.17C108.6 45.17 123.22 86.92 116.91 99.29C110.6 111.66 101 123.29 52.27 118.73C3.54 114.17 55.78 40.38 65.66 39.54Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M107.36 27.5L100.91 37.87"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.21 24.98L74.27 37.31"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.29 45.72L88.85 47.26"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M73.29 45.72L88.85 47.26; M73.4769 39.9798L88.0831 45.5602; M73.29 45.72L88.85 47.26;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M67.26 47.26L54.19 49.59"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M67.26 47.26L54.19 49.59; M62.8172 42.7891L50.6328 48.0609; M67.26 47.26L54.19 49.59;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M44.69 53.26L115.69 50.88L116.79 56.18H94.63C94 56.18 93.34 77.87 79.81 77.18C66.28 76.49 67.26 61.3 67.26 61.3H65.66C65.66 61.3 63.35 70.63 58.59 71.64C53.83 72.65 40.85 75 45.79 58.74L43.07 57.55L44.69 53.26Z"
        fill="black"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M44.69 53.26L115.69 50.88L116.79 56.18H94.63C94 56.18 93.34 77.87 79.81 77.18C66 76.49 67.26 61.3 67.26 61.3H65.66C65.66 61.3 63.35 70.63 58.59 71.64C53.83 72.65 40.85 75 45.79 58.74L43.07 57.55L44.69 53.26Z; M43.83 49.82L114.85 48.33L115.88 53.64H93.63C93 53.36 92.17 75.04 78.65 74.18C65 73.32 66.30 58.14 66.30 58.14H64.66C64 58.12 62.27 67.42 57.50 68.3724C52 69.32 39.72 71.51 44.86 55.3133L42 54.0894L43 49.82Z; M44.69 53.26L115.69 50.88L116.79 56.18H94.63C94 56.18 93.34 77.87 79.81 77.18C66 76.49 67.26 61.3 67.26 61.3H65.66C65.66 61.3 63.35 70.63 58.59 71.64C53.83 72.65 40.85 75 45.79 58.74L43.07 57.55L44.69 53.26Z;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M18.48 84.64C18.48 84.64 19.67 69.64 31.83 69.06C43.99 68.48 68.47 65.45 67.21 82.88C65.95 100.31 59.21 105.79 26.81 102.57C12.58 100.4 18.48 84.64 18.48 84.64Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.57 77.22C44.7228 77.22 48.9 74.7217 48.9 71.64C48.9 68.5583 44.7228 66.06 39.57 66.06C34.4172 66.06 30.24 68.5583 30.24 71.64C30.24 74.7217 34.4172 77.22 39.57 77.22Z"
        fill="black"
      />
      <path
        d="M36.72 79.55L34.17 93.15L23.46 94.51"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.36 96.21L34.17 93.15"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.85 58.16C88.85 58.16 87.5 69.05 82.85 71.64"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M88.85 58.16C88.85 58.16 87.5 69.05 82.85 71.64; M90.1096 61.7266C90.1096 61.7266 84.8659 71.366 79.5904 72.0734; M88.85 58.16C88.85 58.16 87.5 69.05 82.85 71.64;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M34.17 71.64C34.7552 70.7356 35.5538 69.9889 36.4954 69.4658C37.4371 68.9426 38.4929 68.659 39.57 68.64"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
