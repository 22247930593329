import React from 'react';
import { SvgIcon } from '@mui/material';

type Props = {
  className?: string;
};

function CheckIcon({ className }: Props) {
  return (
    <SvgIcon className={className} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="12" />
      <path
        d="M 7.5 13.059 L 10.5 16.059 L 17.625 8.934"
        stroke="white"
        strokeWidth="2.25"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export { CheckIcon };
