import React, { forwardRef } from 'react';
import { Button, ButtonProps } from '@mui/material';
import cn from 'classnames';

import styles from './TransparentButton.module.scss';

type Props = Omit<ButtonProps, 'disableRipple' | 'className'> & {
  highlighted?: boolean;
  hovered?: boolean;
  text: string;
};

export const TransparentButton = forwardRef(
  (
    { highlighted, hovered, text, ...rest }: Props,
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <Button
      className={cn(styles.root, {
        [styles.hovered]: hovered,
      })}
      disableRipple
      ref={ref}
      {...rest}
    >
      {text}
    </Button>
  )
);

TransparentButton.displayName = 'TransparentButton';
