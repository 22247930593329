import React, { useEffect, useState } from 'react';

// TrustPilot
import trustpilotLogo from '../../images/reviews/trustpilot-icon.svg';
import defaultAvatar from '../../images/reviews/default-avatar.png';
import avatarAshleyArmstrong from '../../images/reviews/avatar/AshleyArmstrong_avatar.png';
import avatarGrayMurray from '../../images/reviews/avatar/GrayMurray_avatar.png';
import avatarShaunGrundlingh from '../../images/reviews/avatar/ShaunGrundlingh_avatar.png';
import avatarDarline from '../../images/reviews/avatar/Darline_avatar.png';
// Youtube
import youtubeLogo from '../../images/reviews/youtube-logo.png';
import channelSimonMcFadyen from '../../images/reviews/channel/SimonMcFadyen_channel.jpeg';
import videoPreviewSimonMcFadyen from '../../images/reviews/video-preview/SimonMcFadyen_video-preview.jpeg';
import channelProjectLifeMastery from '../../images/reviews/channel/ProjectLifeMastery_channel.jpeg';
import videoPreviewProjectLifeMastery from '../../images/reviews/video-preview/ProjectLifeMastery_video-preview.jpeg';
import channelTatianaJames from '../../images/reviews/channel/TatianaJames_channel.jpeg';
import videoPreviewTatianaJames from '../../images/reviews/video-preview/TatianaJames_video-preview.jpeg';
// Media
import ambLogo from '../../images/reviews/media-logo/AMB_CRYPRO_large.png';
import beincryptoLogo from '../../images/reviews/media-logo/beincrypto_logo.svg';
import ripplecoinLogo from '../../images/reviews/media-logo/Ripple_news_large.png';
import captainaltcoinLogo from '../../images/reviews/media-logo/cap_altcoin_large.png';
import cryptoPotatoLogo from '../../images/reviews/media-logo/cryptopot_large.png';

import { DoodleRocket } from '../Doodle';
import { ReviewProps, ReviewsList, ReviewType } from '../ReviewsList/ReviewsList';
import { ReviewCardColor, ReviewCardType } from '../ReviewsList/ReviewCard/ReviewCard';
import { SwitchButtonProps, SwitchButtons } from '../SwitchButtons/SwitchButtons';

import styles from './HomeReviews.module.scss';

type Filter = ReviewType | 'all';

export const HomeReviews = () => {

  const reviews: ReviewProps[] = getReviewListWithIds([
    {
      content: {
        icon: avatarGrayMurray,
        author: 'Gray Murray',
        text: 'Look, in my opinion, bitsgap is a pretty revolutionary piece of software that’s being produced very freaking quickly by a company that really cares about helping us make great money.',
        type: ReviewCardType.PERSONAL,
        source: {
          name: 'trustpilot.com',
          logo: trustpilotLogo,
        },
      },
      type: 'people',
    },
    {
      content: {
        author: <img width={140} height={30} src={cryptoPotatoLogo} alt="CryptoPotato" />,
        text: 'Bitsgap: Crypto Trading Bot That Helps Avoiding Bad Habits',
        type: ReviewCardType.MEDIA,
        color: ReviewCardColor.BLUE,
        source: {
          name: 'cryptopotato.com',
        },
      },
      type: 'media',
    },
    {
      content: {
        icon: channelSimonMcFadyen,
        author: 'Simon McFadyen',
        text: 'Bitsgap Trading Bot Tutorial For Beginners',
        type: ReviewCardType.VIDEO,
        preview: videoPreviewSimonMcFadyen,
        source: {
          name: 'youtube.com',
          logo: youtubeLogo,
        },
      },
      type: 'media',
    },
    {
      content: {
        icon: avatarAshleyArmstrong,
        author: 'Ashley Armstrong',
        text: 'I’ve been using Bitsgap for a few months and have seen amazing results! It’s important to know what to do otherwise you will not get any results and so far it’s provided a 34% profit for me.. Great support team too.',
        type: ReviewCardType.PERSONAL,
        source: {
          name: 'trustpilot.com',
          logo: trustpilotLogo,
        },
      },
      type: 'people',
    },
    {
      content: {
        icon: channelTatianaJames,
        author: 'Tatiana James',
        text: 'My New PASSIVE Income Stream Using Crypto Bots',
        type: ReviewCardType.VIDEO,
        preview: videoPreviewTatianaJames,
        source: {
          name: 'youtube.com',
          logo: youtubeLogo,
        },
      },
      type: 'media',
    },
    {
      content: {
        author: <img width={233} height={30} src={ambLogo} alt="AMBCrypto" />,
        text: 'Want a hassle-free trading experience? Try Bitsgap cross-exchange platform',
        type: ReviewCardType.MEDIA,
        color: ReviewCardColor.BLUE,
        source: {
          name: 'ambcrypto.com',
        },
      },
      type: 'media',
    },
    {
      content: {
        icon: channelProjectLifeMastery,
        author: 'Project Life Mastery',
        text: 'How I\'ve Made $17,703 In Passive Income',
        type: ReviewCardType.VIDEO,
        preview: videoPreviewProjectLifeMastery,
        source: {
          name: 'youtube.com',
          logo: youtubeLogo,
        },
      },
      type: 'media',
    },
    {
      content: {
        author: <img width={150} height={42} src={beincryptoLogo} alt="BeInCrypto" />,
        text: 'Bitsgap has made its mission to help all traders maximize their profits',
        type: ReviewCardType.MEDIA,
        color: ReviewCardColor.ORANGE,
        source: {
          name: 'beincrypto.com',
        },
      },
      type: 'media',
    },
    {
      content: {
        icon: avatarShaunGrundlingh,
        author: 'Shaun Grundlingh',
        text: 'I was introduced to Bitsgap just over a month ago and I’m seriously impressed. I’ve used many other bots in both forex and crypto, and they not even worth mentioning when compared to Bitsgap.',
        type: ReviewCardType.PERSONAL,
        source: {
          name: 'trustpilot.com',
          logo: trustpilotLogo,
        },
      },
      type: 'people',
    },
    {
      content: {
        author: <img width={140} height={30} src={ripplecoinLogo} alt="Ripple Coin News" />,
        text: 'One of the most popular automated trading robots in the market',
        type: ReviewCardType.MEDIA,
        color: ReviewCardColor.BLUE,
        source: {
          name: 'ripplecoinnews.com',
        },
      },
      type: 'media',
    },
    {
      content: {
        icon: defaultAvatar,
        author: 'Robert Mellors',
        text: 'Bitsgap is the best auto bot software I have experienced in the last ten years of looking. Your funds are safe as they stay on your exchange and you have full control over them totally secure. Once you get your head round that and learn how to set up your bots you can sleep peacefully at night knowing all is safe and you are earning money.',
        type: ReviewCardType.PERSONAL,
        source: {
          name: 'trustpilot.com',
          logo: trustpilotLogo,
        },
      },
      type: 'people',
    },
    {
      content: {
        author: <img width={210} height={60} src={captainaltcoinLogo} alt="CaptainAltcoin" />,
        text: 'Bitsgap blows its competition out of the water',
        type: ReviewCardType.MEDIA,
        color: ReviewCardColor.ORANGE,
        source: {
          name: 'captainaltcoin.com',
        },
      },
      type: 'media',
    },
    {
      content: {
        icon: avatarDarline,
        author: 'Darlene',
        text: 'Bitsgap ROCKS! I don’t know what I would do without Bitsgap. Once I got used to it, I found the interface very easy to use and intuitive. I can watch all of my crypto currency bots in one place and make adjustments as needed. Tech support is extremely quick and was able to help me in no time. I would totally recommend this product!',
        type: ReviewCardType.PERSONAL,
        source: {
          name: 'trustpilot.com',
          logo: trustpilotLogo,
        },
      },
      type: 'people',
    },
  ]);

  const [filter, setFilter] = useState<Filter>('all');
  const [filteredReviews, setFilteredReviews] = useState<ReviewProps[]>(reviews);

  const filterButtons: SwitchButtonProps<Filter>[] = [
    {
      value: 'all',
      label: 'All',
    },
    {
      value: 'media',
      label: 'Media',
    },
    {
      value: 'people',
      label: 'People',
    },
  ];

  useEffect(() => {
    setFilteredReviews(
      reviews.filter(({ type }) => {
        return filter === 'all' ? true : type === filter;
      })
    );
  }, [filter]);

  return (
    <section className={styles.root} data-test="home-reviews">
      <h2 className={styles.title}>What others say</h2>
      <div className={styles.filter}>
        <SwitchButtons<Filter> buttons={filterButtons} value={filter} onChange={setFilter} />
      </div>
      <ReviewsList list={filteredReviews} />
      <div className={styles.doodleRocket}>
        <DoodleRocket strokeWidth={2} />
      </div>
    </section>
  );

  function getReviewListWithIds(list: Omit<ReviewProps, 'id'>[]): ReviewProps[] {
    return list.map((review, index) => ({ ...review, id: index.toString() }));
  }
};
