import cn from "classnames";
import React, { forwardRef, ForwardedRef, ReactElement } from "react";

import styles from "./LinksList.module.scss";
import { FakeLink } from "../../FakeLink/FakeLink";

export enum LinksListVariant {
  MEDIUM = "medium",
  LARGE = "large",
}

type Props = {
  items: string[];
  variant?: LinksListVariant;
  withoutGaps?: boolean;
};

export const LinksList = forwardRef(function LinksList(
  { items, withoutGaps, variant = LinksListVariant.MEDIUM }: Props,
  ref: ForwardedRef<HTMLUListElement>
) {
  return (
    <ul
      ref={ref}
      className={cn(
        styles.root,
        { [styles.withoutGaps]: withoutGaps },
        styles[variant]
      )}
    >
      {items.map((item: string, index) => {
        return (
          <li key={item} title={item}>
            <FakeLink>
              <a className={styles.listItem}>{item}</a>
            </FakeLink>
          </li>
        );
      })}
    </ul>
  );
}) as <T extends string>(
  props: Props & { ref?: React.ForwardedRef<HTMLUListElement> }
) => ReactElement | null;
