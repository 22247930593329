import React, { useState } from "react";
import cn from "classnames";

import { ReactComponent as Logo } from "../../images/menu.svg";
import { ReactComponent as MenuIcon } from "../../images/menu.svg";

import styles from "./Header.module.scss";
import { HeaderLogo } from "./HeaderLogo/HeaderLogo";
import { HeaderMainMenu } from "./HeaderMainMenu/HeaderMainMenu";
import {
  getCompanyNavLinks,
  getPlatformNavLinks,
  getResourcesNavLinks,
} from "./helper";
import { Container } from "../Container/Container";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { FakeLink } from "../FakeLink/FakeLink";
import { AuthButtons } from "../AuthButtons/AuthButtons";
import { TransparentButton } from "../TransparentButton/TransparentButton";

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      {renderDesktop()}
      {renderMobileAndTablet()}
    </>
  );

  function renderMobileAndTablet() {
    return (
      <header className={cn(styles.root, styles.mobileAndTablet)}>
        <HeaderLogo />
        <button
          onClick={() => setIsMenuOpen(true)}
          className={styles.menuButton}
        >
          <MenuIcon />
        </button>
        <HeaderMainMenu
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          onOpen={() => setIsMenuOpen(true)}
        />
      </header>
    );
  }

  function renderDesktop() {
    const platformMenuItems = getPlatformNavLinks();
    const companyMenuItems = getCompanyNavLinks();
    const resourcesMenuItems = getResourcesNavLinks();

    return (
      <header className={cn(styles.root, styles.desktop)}>
        <Container>
          <div className={styles.wrapper}>
            <div className={styles.side}>
              <HeaderLogo />
              <nav className={styles.navigation}>
                <DropdownMenu label="Platform" items={platformMenuItems} />
                <DropdownMenu label="Company" items={companyMenuItems} />
                <DropdownMenu label="Resources" items={resourcesMenuItems} />
                <FakeLink>
                  <TransparentButton text={"Pricing"} />
                </FakeLink>
              </nav>
            </div>
            <div className={cn(styles.side, styles.rightSide)}>
              <AuthButtons />
              <div className={styles.languageSelect}>
                {/*<DropdownMenu
                  withoutGaps
                  withoutHighlight
                  label={LANGUAGES_MAP[locale].short}
                  items={languageMenuItems}
                  selectedIndex={selectedLanguageIndex}
                  variant={LinksListVariant.LARGE}
                  onClick={handleLocaleChange}
                />*/}
              </div>
            </div>
          </div>
        </Container>
      </header>
    );
  }
};
