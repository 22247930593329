import React from 'react';

type Props = {
  size?: number;
  strokeWidth?: number;
  clickMode?: boolean;
};

export function DoodleEthereum({ strokeWidth = 1, size = 100, clickMode = false }: Props) {
  const begin = clickMode ? 'ethereum.click' : 0;
  const duration = '2s';
  const repeatCount = clickMode ? 1 : 'indefinite';

  return (
    <svg
      id={clickMode ? 'ethereum' : ''}
      width={size}
      height={size}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="bag">
        <path
          d="M61 58C61 58 34.18 72 28.6 85.89C23.02 99.78 30 112.75 56.82 115.27C83.64 117.79 99.29 113.11 103.43 100.33C107.57 87.55 82.38 62.89 80.76 59.47"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.85 62.28C82.85 62.28 98.19 55.62 82.85 47.18C67.51 38.74 50.84 45.7 50.23 54C49.82 59.67 56.29 60.67 56.29 60.67"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M61.76 36.46C61.76 36.46 63.39 39.41 65.52 36.46C67.65 33.51 63.84 21.46 72.78 21.46C81.72 21.46 90.42 25.05 88.94 29.61C87.46 34.17 85.66 36.61 89.11 36.73C92.56 36.85 93.63 41.28 90.95 44.36C89.4304 46.1554 87.6265 47.6891 85.61 48.9"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M57.3 45.72C57.3 45.72 50 35.52 53.7 33.11C57.4 30.7 66.31 32.53 66.31 32.53"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.79 55.3C55.79 55.3 56.44 49.55 66.87 48.9C81.06 48.03 82.63 54.84 82.51 57.43"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g className="ethereum">
          <path d="M64.16 79.34L52.68 81.28L61.35 70.51L66.87 64.13L64.16 79.34Z" fill="#FF5E8D" />
          <path
            d="M62.81 97.41L74.83 92.62L65.36 104.24L61.76 108.54L62.81 97.41Z"
            fill="#FF5E8D"
          />
          <path d="M62.12 97.51L61.68 108.75L54.4 95.64L51.74 90.71L62.12 97.51Z" fill="#274AF2" />
          <path d="M64.16 79.34L66.76 65.11L73.4 77.24L75.65 83.63L64.16 79.34Z" fill="#274AF2" />
          <path d="M63.81 89.65L52.68 81.28L64.16 79.34L75.65 83.63L63.81 89.65Z" fill="#FED55C" />
          <path
            d="M50.84 89.65L62.48 97.51L75.65 91.9L61.76 108.54L50.84 89.65Z"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M62.48 97.51L61.76 108.54"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M52.68 81.28L66.87 64.13L75.65 83.63L63.81 89.65L52.68 81.28Z"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M75.65 83.63L64.16 79.34L52.68 81.28"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M64.16 79.34L66.87 64.13"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 65, 69; 9, 65, 69; 0, 65, 69;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </g>
      <path
        d="M43.66 8.92001C43.66 8.92001 37.55 21.92 43.66 22.46C49.77 23 47.07 13.25 37.33 14.06"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -5, 10; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M109.48 55.36C109.48 55.36 99.64 44.29 97.09 49.89C95.46 53.46 104.09 56.74 107.33 47.5"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5, -5; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
    </svg>
  );
}
