import React from 'react';

type Props = {
  strokeWidth?: number;
};

export function DoodlePizza({ strokeWidth = 1 }: Props) {
  const duration = '2s';

  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M30 87.41L76.43 17.05L103 95.34C103 95.34 68.39 112.2 30 87.41Z" fill="#FFD45C" />
      <path
        d="M80.43 29.49C80.43 29.49 72.82 33.49 75.58 40.55C77.75 46.12 85.22 43 85.22 43"
        fill="#EC5F32"
      />
      <path
        d="M45 64.69C45 64.69 50.4 69.34 47.67 75.69C44.94 82.04 37.1 77.69 37.1 77.69"
        fill="#EC5F32"
      />
      <path
        d="M103.22 95.26C103.22 95.26 109.54 93.78 110.22 101.54C110.9 109.3 92.83 116.11 64.85 112.69C43.35 110.06 29.4 100.25 24.08 95.84C22.15 94.24 20.89 91.52 21.81 89.19C22.2047 88.2211 22.9076 87.4093 23.81 86.88C24.7901 86.4154 25.8761 86.2197 26.9567 86.3128C28.0373 86.4059 29.0738 86.7846 29.96 87.41L76.43 17.05L103.22 95.26C103.22 95.26 82.81 102.93 67.36 100.87C54.1228 98.9632 41.4117 94.3837 30 87.41"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45 64.69C45 64.69 50.4 69.34 47.67 75.69C44.94 82.04 37.1 77.69 37.1 77.69"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.06 78.53C97.06 78.53 89.13 82 90.9 88.08C92.25 92.67 101.12 90.19 101.12 90.19"
        fill="#EC5F32"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.43 29.49C80.43 29.49 72.82 33.49 75.58 40.55C77.75 46.12 85.22 43 85.22 43"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.93 62.89C58.9002 62.89 63.74 58.0502 63.74 52.08C63.74 46.1098 58.9002 41.27 52.93 41.27C46.9598 41.27 42.12 46.1098 42.12 52.08C42.12 58.0502 46.9598 62.89 52.93 62.89Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.52 69.4399C79.4902 69.4399 84.33 64.6001 84.33 58.6299C84.33 52.6597 79.4902 47.8199 73.52 47.8199C67.5498 47.8199 62.71 52.6597 62.71 58.6299C62.71 64.6001 67.5498 69.4399 73.52 69.4399Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.65 86.52C69.65 86.52 67.13 82.79 68.72 82.42C70.3163 82.2914 71.9061 82.7364 73.2038 83.6748C74.5015 84.6133 75.4221 85.9837 75.8 87.54C76.73 91.83 74.22 94.16 73.47 94.16C72.72 94.16 71.47 91.09 71.47 91.09C71.47 91.09 66.47 95.66 64.47 92.21C62.47 88.76 67.69 86.24 69.65 86.52Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 70, 88.25; -17.75, 70, 88.25; 0, 70, 88.25;"
          dur={duration}
          repeatCount="indefinite"
          additive="sum"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 4 -2; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
          additive="sum"
        />
      </path>
      <path
        d="M49.05 54.72C51.7783 54.72 53.99 52.5083 53.99 49.78C53.99 47.0517 51.7783 44.84 49.05 44.84C46.3217 44.84 44.11 47.0517 44.11 49.78C44.11 52.5083 46.3217 54.72 49.05 54.72Z"
        fill="black"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5 4; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M69.5 61.67C72.2283 61.67 74.44 59.4583 74.44 56.73C74.44 54.0018 72.2283 51.79 69.5 51.79C66.7717 51.79 64.56 54.0018 64.56 56.73C64.56 59.4583 66.7717 61.67 69.5 61.67Z"
        fill="black"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 3 2; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M52.93 71.92C52.93 71.92 56.17 79.09 64.74 74.26"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M52.93 71.92C52.93 71.92 56.17 79.09 64.74 74.26; M52.93 71.92C52.93 71.92 61 82 64.74 74.26 74.26; M52.93 71.92C52.93 71.92 56.17 79.09 64.74 74.26;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}
