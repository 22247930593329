import React from "react";
import cn from "classnames";

import line01 from "../../images/start-trial/line-01.png";
import line02 from "../../images/start-trial/line-02.png";
import line03 from "../../images/start-trial/line-03.png";

import { ButtonSize, ButtonVariant } from "../../styles/theme";
import { devices } from "../../styles/devices";

import styles from "./StartTrial.module.scss";
import {
  DoodleBitcoin,
  DoodleDog,
  DoodleEthereum,
  DoodlePizza,
  DoodleRobot,
  DoodleRocket,
  Doodles,
  DoodleType,
} from "../Doodle";
import { Container } from "../Container/Container";
import { Button } from "../Button/Button";
import { FakeLink } from "../FakeLink/FakeLink";
import { Marker, MarkerHeading } from "../Marker/Marker";

const doodles: DoodleType[] = [
  {
    className: styles.doodleBitcoin,
    Doodle: DoodleBitcoin,
  },
  {
    className: styles.doodleDog,
    Doodle: DoodleDog,
  },
  {
    className: styles.doodleEthereum,
    Doodle: DoodleEthereum,
  },
  {
    className: styles.doodlePizza,
    Doodle: DoodlePizza,
  },
  {
    className: styles.doodleRobot,
    Doodle: DoodleRobot,
  },
  {
    className: styles.doodleRocket,
    Doodle: DoodleRocket,
  },
];

export const StartTrial = () => {
  return (
    <section className={cn(styles.root, styles.home)}>
      <Container>
        <div className={styles.startTrial}>
          <h2 className={styles.title}>
            <Marker heading={MarkerHeading.H2}>Try free for 7 days</Marker>
          </h2>
          <div className={styles.content}>
            <div className={styles.steps}>
              <h3 className={cn(styles.step, styles.signUp)}>Sign Up</h3>
              <span className={devices.mobileTablet}>
                <img src={line01} width={16} height={120} alt="line" />
              </span>
              <h3 className={cn(styles.step, styles.connect)}>Connect</h3>
              <span className={devices.mobileTablet}>
                <img src={line02} width={16} height={103} alt="line" />
              </span>
              <div className={styles.stepWrapper}>
                <h3 className={cn(styles.step, styles.startTrading)}>
                  Start trading
                </h3>
              </div>
              <span className={devices.mobileTablet}>
                <img src={line03} width={16} height={59} alt="line" />
              </span>
            </div>
            <p className={styles.description}>
              All your data is secured with high-end encryption.
            </p>
            <div className={styles.button}>
              <FakeLink>
                <Button
                  variant={ButtonVariant.CONTAINED}
                  size={ButtonSize.MEDIUM}
                  uppercase
                >
                  <span className={styles.buttonContent}>Start free trial</span>
                </Button>
              </FakeLink>
            </div>
          </div>
        </div>
        <Doodles doodles={doodles} />
      </Container>
    </section>
  );
};
