import React, { useEffect, useRef } from 'react';

type Props = {
  strokeWidth?: number;
  animate?: boolean;
  size?: number;
  clickMode?: boolean;
};

export function DoodleRocket({
  strokeWidth = 1,
  animate = true,
  size = 140,
  clickMode = false,
}: Props) {
  const imageRef = useRef<SVGSVGElement>(null);
  const begin = clickMode ? 'rocket.click' : 0;
  const duration = '2s';
  const repeatCount = clickMode ? 1 : 'indefinite';

  useEffect(() => {
    imageRef.current?.[animate ? 'unpauseAnimations' : 'pauseAnimations']();
  }, [animate]);

  return (
    <svg
      ref={imageRef}
      id={clickMode ? 'rocket' : ''}
      width={size}
      height={size}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.65 88.58C45.65 88.58 38.73 90.88 37.82 102.46C40.5686 102.554 43.2924 101.912 45.7097 100.6C48.127 99.2887 50.1502 97.3553 51.57 95"
        fill="#FED55C"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M45.65 88.58C45.65 88.58 38.73 90.88 37.82 102.46C40.5686 102.554 43.2924 101.912 45.7097 100.6C48.127 99.2887 50.1502 97.3553 51.57 95; M49.2502 84C49.2502 84 40.1913 87.4776 39 104.986C42.5982 105.128 46.1638 104.157 49.3283 102.175C52.4928 100.192 56.1413 95.0613 58 91.5; M45.65 88.58C45.65 88.58 38.73 90.88 37.82 102.46C40.5686 102.554 43.2924 101.912 45.7097 100.6C48.127 99.2887 50.1502 97.3553 51.57 95;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <g className="rocket">
        <path
          d="M45.65 84.5699L44.14 86.9399L52.72 96.2699L55.27 95.2799"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.9 93.73C64.9 93.73 74.53 103.41 74.11 123.24C74.11 123.24 96.11 110 86.81 79.66"
          fill="#EC5F32"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.29 76.07C47.29 76.07 38.14 65.9 19 65.92C19 65.92 32.26 43.47 61.37 53.7"
          fill="#EC5F32"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.47 96.61C56.47 96.61 71.1 98.2 95.47 72.86C124.26 42.86 124.17 21.56 117.18 18.03C110.19 14.5 96.87 17.12 72.52 39.87C47.25 63.46 44.17 82.87 44.17 82.87L56.47 96.61Z"
          fill="white"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.26 58.43C96.4239 58.43 100.61 54.1006 100.61 48.76C100.61 43.4194 96.4239 39.09 91.26 39.09C86.0961 39.09 81.91 43.4194 81.91 48.76C81.91 54.1006 86.0961 58.43 91.26 58.43Z"
          fill="#513AFD"
        />
        <path
          d="M91.26 61.53C98.0752 61.53 103.6 55.8127 103.6 48.76C103.6 41.7073 98.0752 35.99 91.26 35.99C84.4448 35.99 78.92 41.7073 78.92 48.76C78.92 55.8127 84.4448 61.53 91.26 61.53Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.79 95.17C44.79 95.17 59.89 66.27 74 62.37L76.51 64.78C76.51 64.78 69.62 83.83 44.79 95.17Z"
          fill="#EC5F32"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.89 22.55L115.9 43.89"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.58 24.46L114.45 47.92"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.26 57.43C95.8881 57.43 99.64 53.5483 99.64 48.76C99.64 43.9717 95.8881 40.09 91.26 40.09C86.6319 40.09 82.88 43.9717 82.88 48.76C82.88 53.5483 86.6319 57.43 91.26 57.43Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5 -5; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </g>
      <path
        d="M50.57 105.79L36 118.8"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -1 1; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M58.29 105L44 118.01"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5 -5; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M66.29 23L52 36.01"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 6 -6; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M74.29 22L60 35.01"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -6 6; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
    </svg>
  );
}
