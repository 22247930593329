import React from "react";
import cn from "classnames";

import { ButtonSize, ButtonVariant } from "../../styles/theme";
import { devices } from "../../styles/devices";
import { Button } from "../Button/Button";

import styles from "./AuthButtons.module.scss";
import { TransparentButton } from "../TransparentButton/TransparentButton";
import { FakeLink } from "../FakeLink/FakeLink";

const AuthButtons = () => {
  return (
    <>
      <div className={cn(styles.root, devices.desktop)}>
        <FakeLink>
          <Button variant={ButtonVariant.OUTLINED} size={ButtonSize.SMALL}>
            Sign up
          </Button>
        </FakeLink>

        <div className={styles.loginButton}>
          <FakeLink>
            <TransparentButton text={"Log in"} />
          </FakeLink>
        </div>
      </div>
      <div className={cn(styles.root, devices.mobileTablet)}>
        <FakeLink>
          <Button
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.X_SMALL}
            fullWidth
          >
            <span className={styles.buttonText}>Sign up</span>
          </Button>
        </FakeLink>
        <FakeLink>
          <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.SMALL}
            fullWidth
          >
            <span className={styles.buttonText}>Log in</span>
          </Button>
        </FakeLink>
      </div>
    </>
  );
};

export { AuthButtons };
