import React from 'react';

type Props = {
  strokeWidth?: number;
};

export function DoodleBull({ strokeWidth = 1 }: Props) {
  const duration = '2s';

  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.2 122.2C62.2 122.2 100.68 127.61 115.14 91.79L99.59 65C99.59 65 110.59 63.34 112.05 56.29C113.51 49.24 78.59 37.84 69.18 43.13C69.18 43.13 68.34 42.34 58.34 44.69C48.34 47.04 27.1 87.92 27.78 94.24C28.46 100.56 31.35 114.24 60.51 109.7L62.2 122.2Z"
        fill="#EC5F32"
      />
      <path
        d="M34.94 84.58C34.94 84.58 45.08 38.85 69.18 43.13C74.9464 41.1859 81.1754 41.088 87 42.85C96.46 45.85 99.61 54.85 99.61 54.85C99.61 54.85 102.99 49.67 109.53 53.05C116.07 56.43 106.13 65 99.59 65L115.14 91.8C115.14 91.8 101.14 127.62 62.2 122.21L60.4 110.5"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.61 20.08C29.3569 21.4632 32.7202 23.7196 35.421 26.6621C38.1218 29.6046 40.0823 33.1485 41.14 37C44.8 50.25 51.32 49.8 51.32 49.8L42.32 62.74C42.32 62.74 26.2 65.5 25.61 55.86C25.02 46.22 36.62 39.74 25.61 20.08Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M98.58 7.76999C98.08 8.42999 93.29 18.77 100.03 29.53C100.47 30.1738 100.775 30.8997 100.928 31.6643C101.081 32.4289 101.078 33.2165 100.92 33.98C99.5 39.62 89.57 44.48 87.84 44.62C85.78 44.79 86.1 53.77 89.17 55.62C92.24 57.47 109.35 55.14 113.29 48.44C117.23 41.74 114.29 31.34 106.45 22.79C98.61 14.24 98.58 7.76999 98.58 7.76999Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.05 98.5C65.05 98.5 67.65 91.95 63.81 88.89C57.36 83.69 46.17 92 46.17 92C46.17 92 35.09 84.42 29.78 87.7C24.47 90.98 30.91 104.55 30.91 104.55C30.91 104.55 33.16 108.91 37.78 105.87C44.37 101.52 47.69 102.53 51.23 104.99C54.77 107.45 59.23 109.4 62.81 105.58"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.6532 99.4804C40.4524 98.5947 40.1761 97.0429 39.0362 96.0143C37.8963 94.9858 36.3244 94.87 35.5252 95.7557C34.7261 96.6414 35.0023 98.1931 36.1422 99.2217C37.2821 100.25 38.8541 100.366 39.6532 99.4804Z"
        fill="black"
      >
        <animate
          attributeName="d"
          values="M39.6532 99.4804C40.4524 98.5947 40.1761 97.0429 39.0362 96.0143C37.8963 94.9858 36.3244 94.87 35.5252 95.7557C34.7261 96.6414 35.0023 98.1931 36.1422 99.2217C37.2821 100.25 38.8541 100.366 39.6532 99.4804Z; M40.2109 99.9836C41.4229 98.6403 41.2316 96.4924 39.7837 95.1859C38.3358 93.8795 36.1796 93.9093 34.9676 95.2525C33.7556 96.5957 33.9468 98.7437 35.3947 100.05C36.8426 101.357 38.9989 101.327 40.2109 99.9836Z; M39.6532 99.4804C40.4524 98.5947 40.1761 97.0429 39.0362 96.0143C37.8963 94.9858 36.3244 94.87 35.5252 95.7557C34.7261 96.6414 35.0023 98.1931 36.1422 99.2217C37.2821 100.25 38.8541 100.366 39.6532 99.4804Z"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M55.4067 99.3918C56.6581 98.5022 57.1122 96.9928 56.4209 96.0205C55.7297 95.0483 54.155 94.9812 52.9036 95.8709C51.6523 96.7605 51.1982 98.2698 51.8894 99.2421C52.5806 100.214 54.1554 100.281 55.4067 99.3918Z"
        fill="black"
      >
        <animate
          attributeName="d"
          values="M55.4067 99.3918C56.6581 98.5022 57.1122 96.9928 56.4209 96.0205C55.7297 95.0483 54.155 94.9812 52.9036 95.8709C51.6523 96.7605 51.1982 98.2698 51.8894 99.2421C52.5806 100.214 54.1554 100.281 55.4067 99.3918Z; M56.1228 100.399C58.0076 99.0592 58.6545 96.7338 57.5678 95.2052C56.4811 93.6766 54.0722 93.5237 52.1875 94.8636C50.3028 96.2035 49.6559 98.5288 50.7426 100.057C51.8293 101.586 54.2381 101.739 56.1228 100.399Z; M55.4067 99.3918C56.6581 98.5022 57.1122 96.9928 56.4209 96.0205C55.7297 95.0483 54.155 94.9812 52.9036 95.8709C51.6523 96.7605 51.1982 98.2698 51.8894 99.2421C52.5806 100.214 54.1554 100.281 55.4067 99.3918Z"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M22.35 68.33L78.44 68C78.44 68 79.82 86.67 64.94 87.19C50.06 87.71 51.76 72.37 51.76 72.37L47.57 72.6C47.57 72.6 44.22 92.37 29.64 86.15C22.64 82.37 22.35 68.33 22.35 68.33Z"
        fill="black"
      >
        <animate
          attributeName="d"
          values="M22.35 68.33L78.44 68C78.44 68 79.82 86.67 64.94 87.19C50.06 87.71 51.76 72.37 51.76 72.37L47.57 72.6C47.57 72.6 44.22 92.37 29.64 86.15C22.64 82.37 22.35 68.33 22.35 68.33Z; M22 66.3579L77.9663 66C77.9663 66 79.3432 86.2508 64.4961 86.8148C49.6489 87.3788 51.3451 70.74 51.3451 70.74L47.1644 70.9895C47.1644 70.9895 43.8218 92.4334 29.2739 85.6868C22.2894 81.5867 22 66.3579 22 66.3579Z; M22.35 68.33L78.44 68C78.44 68 79.82 86.67 64.94 87.19C50.06 87.71 51.76 72.37 51.76 72.37L47.57 72.6C47.57 72.6 44.22 92.37 29.64 86.15C22.64 82.37 22.35 68.33 22.35 68.33Z;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M34.94 107.12C34.94 107.12 48.44 114 59 108.87"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.29 53.22C62.29 53.22 62.8 44.52 69.18 43.13"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.76 82.18L110.66 100.01"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.24 72.72C56.24 72.72 56.66 81.3 62.24 82.13"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M56.24 72.72C56.24 72.72 56.66 81.3 62.24 82.13; M56 71C56 71 56.42 81.0298 62 82; M56.24 72.72C56.24 72.72 56.66 81.3 62.24 82.13;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M28.89 72.72C28.89 72.72 29.31 81.3 34.89 82.13"
        stroke="white"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animate
          attributeName="d"
          values="M28.89 72.72C28.89 72.72 29.31 81.3 34.89 82.13; M29 71C29 71 29.42 81.0298 35 82; M28.89 72.72C28.89 72.72 29.31 81.3 34.89 82.13;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
}
