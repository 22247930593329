import React, { Dispatch, SetStateAction, useState } from "react";
import cn from "classnames";

import {
  BinanceLogo,
  BybitLogo,
  KucoinLogo,
  OkexLogo,
} from "../exchangesLogos";
import {
  DoodleBear,
  DoodleBitcoin,
  DoodleBull,
  DoodleDog,
  DoodleEthereum,
  DoodleEyes,
  DoodleIncognito,
  DoodleRocket,
  Doodles,
  DoodleType,
} from "../Doodle";

import styles from "./MainPromo.module.scss";
import { FakeLink } from "../FakeLink/FakeLink";
import { Container } from "../Container/Container";
import { Marker, MarkerHeading } from "../Marker/Marker";
import { Button } from "../Button/Button";
import { ButtonSize, ButtonVariant } from "../../styles/theme";

type ExchangeLogo<T extends string> = {
  size: T;
  isColorful?: boolean;
};

type Exchange<T extends string> = {
  name: string;
  isColorful: boolean;
  setColorful: Dispatch<SetStateAction<boolean>>;
  // @ts-ignore
  Logo: ({ size: string, isColorful: boolean }: ExchangeLogo<T>) => JSX.Element;
};

const doodles: DoodleType[] = [
  {
    className: styles.doodleBear,
    Doodle: DoodleBear,
  },
  {
    className: styles.doodleBitcoin,
    Doodle: DoodleBitcoin,
  },
  {
    className: styles.doodleBull,
    Doodle: DoodleBull,
  },
  {
    className: styles.doodleDog,
    Doodle: DoodleDog,
  },
  {
    className: styles.doodleEthereum,
    Doodle: DoodleEthereum,
  },
  {
    className: styles.doodleIncognito,
    Doodle: DoodleIncognito,
  },
  {
    className: styles.doodleRocket,
    Doodle: DoodleRocket,
  },
];

export const MainPromo = () => {
  const [isColorfulBinance, setColorfulBinance] = useState(false);
  const [isColorfulOkx, setColorfulOkx] = useState(false);
  const [isColorfulKucoin, setColorfulKucoin] = useState(false);
  const [isColorfulBybit, setColorfulBybit] = useState(false);

  return (
    <section className={cn(styles.root, styles.home)}>
      <Container centered>
        <h1 className={cn(styles.title, styles.home)}>
          Smarter way to automate
          <br className={styles.tabletBreak} /> your
          <Marker heading={MarkerHeading.H1}>crypto trading</Marker>
        </h1>
        <p className={styles.text}>
          Best crypto trading bots, algorithmic orders, portfolio management and
          free Demo mode — all in one place.
        </p>
        <FakeLink>
          <Button
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.MEDIUM}
            uppercase
          >
            <span className={styles.buttonText}>Start 7-day free trial</span>
          </Button>
        </FakeLink>
        <p className={styles.remark}>*No credit card required</p>
        <div className={styles.exchangesContainer}>
          <p className={styles.exchangesText}>
            Trusted by 500,000 traders from
          </p>
          <div className={styles.exchangesWrapper}>
            {renderExchanges()}
            <FakeLink>
              <a className={cn(styles.exchangesText, styles.moreExchanges)}>
                15+ more
              </a>
            </FakeLink>
          </div>
        </div>
      </Container>
      <Doodles doodles={doodles} />
      <DoodleEyes className={styles.doodleEyes} withEyeBrows={false} />
    </section>
  );

  function renderExchanges() {
    const exchanges: Exchange<"small">[] = [
      {
        name: "binance",
        Logo: BinanceLogo,
        isColorful: isColorfulBinance,
        setColorful: setColorfulBinance,
      },
      {
        name: "okx",
        Logo: OkexLogo,
        isColorful: isColorfulOkx,
        setColorful: setColorfulOkx,
      },
      {
        name: "kucoin",
        Logo: KucoinLogo,
        isColorful: isColorfulKucoin,
        setColorful: setColorfulKucoin,
      },
      {
        name: "bybit",
        Logo: BybitLogo,
        isColorful: isColorfulBybit,
        setColorful: setColorfulBybit,
      },
    ];

    return (
      <div className={styles.exchangesList}>
        {exchanges.map(({ name, Logo, isColorful, setColorful }) => {
          return (
            <FakeLink key={name}>
              <a
                className={cn(
                  styles.exchangeLogoWrapper,
                  styles.exchangeLink,
                  styles[name]
                )}
                onFocus={() => setColorful(true)}
                onBlur={() => setColorful(false)}
                onMouseEnter={() => setColorful(true)}
                onMouseLeave={() => setColorful(false)}
              >
                <Logo size="small" isColorful={isColorful} />
              </a>
            </FakeLink>
          );
        })}
      </div>
    );
  }
};
