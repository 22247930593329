type Coords = {
  X: number;
  Y: number;
};

type EyeCoords = {
  right: Coords;
  middle: Coords;
  left: Coords;
};

export const MAX_TRANSLATE_VALUE = 5.43;
export const MAX_DIAGONAL_TRANSLATE_VALUE = 4.39;

export const LEFT_EYES_COORDS: EyeCoords = {
  right: {
    X: 31.06,
    Y: 30.36,
  },
  left: {
    X: 21.52,
    Y: 25.21,
  },
  middle: {
    X: 26.28,
    Y: 27.67,
  },
};

export const RIGHT_EYES_COORDS: EyeCoords = {
  right: {
    X: 53.04,
    Y: 43.06,
  },
  left: {
    X: 43.5,
    Y: 37.91,
  },
  middle: {
    X: 48.28,
    Y: 40.58,
  },
};

export const LEFT_EYES_WITH_EYEBROWS_COORDS: EyeCoords = {
  right: {
    X: 27.17,
    Y: 41,
  },
  middle: {
    X: 23.53,
    Y: 43.39,
  },
  left: {
    X: 18.17,
    Y: 46,
  },
};

export const RIGHT_EYES_WITH_EYEBROWS_COORDS: EyeCoords = {
  right: {
    X: 50.94,
    Y: 33.48,
  },
  middle: {
    X: 48.07,
    Y: 36.43,
  },
  left: {
    X: 42.94,
    Y: 38.48,
  },
};
