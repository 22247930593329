import { ButtonBase } from '@mui/material';
import React from 'react';
import cn from 'classnames';

import styles from './SwitchButtons.module.scss';

type SwitchButton<T extends string> = {
  label: string;
  value: T;
};

type Props<T extends string> = {
  buttons: SwitchButton<T>[];
  value: T;
  onChange(value: T): void;
};

export function SwitchButtons<T extends string>({ buttons, value, onChange }: Props<T>) {
  return (
    <div>
      {buttons.map(({ label, value: buttonValue }) => (
        <ButtonBase
          disableRipple
          key={buttonValue}
          onClick={() => onChange(buttonValue)}
          className={cn(styles.button, { [styles.active]: value === buttonValue })}
        >
          {label}
        </ButtonBase>
      ))}
    </div>
  );
}

export type { SwitchButton as SwitchButtonProps };
