import React from "react";
import cn from "classnames";

import quoteIcon from "../../../images/reviews/quote.svg";
import { devices } from "../../../styles/devices";
import { FakeLink } from "../../FakeLink/FakeLink";

import styles from "./ReviewCard.module.scss";

export enum ReviewCardType {
  PERSONAL = "personal",
  MEDIA = "media",
  VIDEO = "video",
}

export enum ReviewCardColor {
  WHITE = "white",
  BLUE = "blue",
  ORANGE = "orange",
}

type Props = {
  icon?: string;
  author: string | JSX.Element;
  text: string | JSX.Element;
  type: ReviewCardType;
  preview?: string;
  color?: ReviewCardColor;
  source: {
    name: string;
    logo?: string;
  };
};

const DESKTOP_PREVIEW_SIZE = { width: 312, height: 175 };
const MOBILE_PREVIEW_SIZE = { width: 251, height: 140 };

export const ReviewCard = ({
  icon,
  author,
  text,
  type,
  preview,
  color = ReviewCardColor.WHITE,
  source,
}: Props) => {
  return (
    <div>
      <FakeLink>
        <a className={cn(styles.card, styles[color])}>
          <div
            className={cn(styles.header, {
              [styles.media]: type === ReviewCardType.MEDIA,
            })}
            data-test="review-card-logo"
          >
            {icon && (
              <img
                className={cn({
                  [styles.personImg]: type === ReviewCardType.PERSONAL,
                  [styles.channelImg]: type === ReviewCardType.VIDEO,
                })}
                width={48}
                height={48}
                src={icon}
                alt={type === ReviewCardType.PERSONAL ? "avatar" : "logo"}
              />
            )}
            <div className={styles.author}>{author}</div>
          </div>
          {renderContent()}
        </a>
      </FakeLink>
      <div className={styles.sourceRow}>
        From
        {renderButton(
          <>
            {source.logo && (
              <span className={styles.logo}>
                <img
                  width={24}
                  height={24}
                  src={source.logo}
                  alt={source.name}
                />
              </span>
            )}
            <span className={styles.sourceLinkText}>{source.name}</span>
          </>
        )}
      </div>
    </div>
  );

  function renderButton(content: React.ReactNode) {
    if (type === ReviewCardType.VIDEO) {
      return (
        <FakeLink>
          <button className={cn(styles.source, styles.button)}>
            {content}
          </button>
        </FakeLink>
      );
    }

    return (
      <FakeLink>
        <a className={styles.source} target="_blank" rel="noreferrer">
          {content}
        </a>
      </FakeLink>
    );
  }

  function renderContent() {
    switch (type) {
      case ReviewCardType.PERSONAL: {
        return <p className={styles.content}>{text}</p>;
      }

      case ReviewCardType.MEDIA: {
        return (
          <div className={styles.quote}>
            <div className={styles.quoteIconWrapper}>
              <img src={quoteIcon} alt={"quote icon"} />
            </div>
            <div className={styles.quoteText}>{text}</div>
          </div>
        );
      }

      case ReviewCardType.VIDEO: {
        return (
          <div className={styles.videoContent}>
            <p className={styles.videoAnnotation}>{text}</p>
            <div className={styles.videoPreviewWrapper}>
              {preview && (
                <>
                  <span className={devices.mobileTablet}>
                    <img
                      src={preview}
                      alt="video preview"
                      {...MOBILE_PREVIEW_SIZE}
                    />
                  </span>
                  <span className={devices.desktop}>
                    <img
                      src={preview}
                      alt="video preview"
                      {...DESKTOP_PREVIEW_SIZE}
                    />
                  </span>
                </>
              )}
            </div>
          </div>
        );
      }
    }
  }
};

export type { Props as ReviewCardProps };
