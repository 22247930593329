export const devices = {
  mobile: 'mobile',
  tablet: 'tablet',
  desktop: 'desktop',
  mobileDesktop: 'mobile-desktop',
  mobileTablet: 'mobile-tablet',
  tabletDesktop: 'tablet-desktop',
} as const;

export const deviceTypes = ['mobile', 'tablet', 'desktop'] as const;

export type DeviceClass = typeof devices[keyof typeof devices];
