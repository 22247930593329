import React from "react";
import cn from "classnames";

import styles from "./Statistics.module.scss";
import { Container } from "../Container/Container";
import { DoodleMagnet } from "../Doodle";

export enum StatisticsVariant {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

type Props = {
  isHomePage?: boolean;
  variant?: StatisticsVariant;
};

export const Statistics = ({
  variant = StatisticsVariant.HORIZONTAL,
  isHomePage,
}: Props) => {
  const achievements = [
    {
      count: "3.7",
      unit: "M",
      label: "bots started",
    },
    {
      count: "$300",
      unit: "B",
      label: "trading volume",
    },
    {
      count: "500",
      unit: "K",
      label: "traders worldwide",
    },
  ];
  return (
    <section className={styles.root}>
      <Container>{renderContent()}</Container>
      <span className={styles.doodleMagnet}>
        <DoodleMagnet strokeWidth={2} />
      </span>
    </section>
  );

  function renderContent() {
    return (
      <div className={cn(styles.list, styles[variant])} data-test="statistics">
        {achievements.map(({ unit, label, count }) => (
          <div key={label} className={styles.block}>
            <div className={styles.count}>
              {count}
              <span className={styles.unit}>{unit}</span>
            </div>
            <div className={styles.label}>{label}</div>
          </div>
        ))}
      </div>
    );
  }
};
