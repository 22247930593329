import React from "react";

import TrustPilot from "../../images/home/trust-box/trustpilot.svg";
import { devices } from "../../styles/devices";

import styles from "./TrustBox.module.scss";
import { FakeLink } from "../FakeLink/FakeLink";

export type TrustBoxParams = {
  reviewsQuantity: number;
  trustScore: number;
  trustScoreText: string;
};

export const TrustBox = ({
  reviewsQuantity,
  trustScore,
  trustScoreText,
}: TrustBoxParams) => {
  const flooredScore = Math.floor(trustScore);
  const normalizedStarsValue =
    flooredScore + 0.25 > trustScore ? flooredScore : flooredScore + 0.5;

  return (
    <FakeLink>
      <a className={styles.root} rel={"noreferrer"} target={"_blank"}>
        <strong>{trustScoreText}</strong>
        <div className={styles.stars}>
          <img
            src={`/images/home/trust-box/stars/stars-${normalizedStarsValue}.svg`}
            width={170}
            height={32}
            alt={String(trustScore)}
          />
        </div>
        <div className={styles.ratingBlock}>
          <span className={devices.mobileTablet}>
            <strong>{trustScore}</strong> out of 5
          </span>
          <span className={devices.desktop}>
            <strong>{trustScore}</strong> out of 5 based on{" "}
            <strong>{reviewsQuantity} reviews</strong>
          </span>
          <span className={styles.logo}>
            <img src={TrustPilot} alt={"TrustPilot"} />
          </span>
        </div>
      </a>
    </FakeLink>
  );
};
