import { useEffect, useRef } from 'react';
import { useInView } from 'react-cool-inview';
import { DESKTOP_MEDIA_QUERY, MOBILE_MEDIA_QUERY, TABLET_MEDIA_QUERY } from '../media.constants';


type Props = {
  mobile?: string[];
  tablet?: string[];
  desktop?: string[];
  allDevices?: string[];
};
/*
 * Preload images when scrolled to certain element or trigger preload manually
 */
export const usePreloadImages = (
  { mobile, tablet, desktop, allDevices }: Props = {},
  { manualPreload = false } = {}
) => {
  const preloadedImagesRef = useRef<string[]>([]);
  const unloadImages = () => {
    preloadedImagesRef.current.forEach((url) => {
      const linkPreloadElement = document.querySelector(`link[rel="preload"][href="${url}"]`);
      linkPreloadElement?.parentNode?.removeChild(linkPreloadElement);
    });
  };
  const preloadImage = (src: string, media?: string) => {
    const alreadyPreloaded = preloadedImagesRef.current.includes(src);

    if (!alreadyPreloaded) {
      preloadedImagesRef.current.push(src);

      document.head.insertAdjacentHTML(
        'beforeend',
        `<link rel="preload" href="${src}" as="image" media="${media}">`
      );
    }
  };
  const preloadImages = () => {
    mobile?.forEach((src) => preloadImage(src, MOBILE_MEDIA_QUERY));
    tablet?.forEach((src) => preloadImage(src, TABLET_MEDIA_QUERY));
    desktop?.forEach((src) => preloadImage(src, DESKTOP_MEDIA_QUERY));
    allDevices?.forEach((src) => preloadImage(src));
  };

  useEffect(() => unloadImages, []);

  const { observe: observeElement } = useInView({
    onEnter: preloadImages,
  });

  return manualPreload ? preloadImages : observeElement;
};
