import React, { useRef, useState } from "react";
import { ClickAwayListener, Grow, Box, Popper } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { LinksList, LinksListVariant } from "./LinksList/LinksList";
import { TransparentButton } from "../TransparentButton/TransparentButton";
import styles from "./DropdownMenu.module.scss";

type Props = {
  label: string;
  items: string[];
  variant?: LinksListVariant;
};

export const DropdownMenu = ({ label, items, variant }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const linksList = <LinksList items={items} variant={variant} />;

  return (
    <ClickAwayListener onClickAway={handleMenuClose}>
      <Box>
        <TransparentButton
          ref={buttonRef}
          endIcon={<ExpandMore className={styles.arrowIcon} />}
          onClick={handleMenuToggle}
          classes={{
            endIcon: styles.endIcon,
          }}
          fullWidth
          text={label}
        />
        <Popper
          className={styles.popper}
          open={isOpen}
          anchorEl={buttonRef.current}
          transition
          disablePortal
          modifiers={[
            {
              name: "flip",
              enabled: false,
            },
          ]}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
              <div className={styles.content}>{linksList}</div>
            </Grow>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );

  function handleMenuToggle() {
    setIsOpen(!isOpen);
  }

  function handleMenuClose(event: MouseEvent | TouchEvent) {
    if (
      buttonRef.current &&
      buttonRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setIsOpen(false);
  }
};
