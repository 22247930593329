import React from "react";

import LogoIcon from "../../../images/logo.svg";

import styles from "./HeaderLogo.module.scss";
import { FakeLink } from "../../FakeLink/FakeLink";

export const HeaderLogo = () => {
  return (
    <FakeLink>
      <a className={styles.root}>
          <img src={LogoIcon} alt="logo"/>
      </a>
    </FakeLink>
  );
};
