import React from 'react';

type Props = {
  strokeWidth?: number;
};

export function DoodleIncognito({ strokeWidth = 1 }: Props) {
  const duration = '3s';

  return (
    <svg
      width="140"
      height="140"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="coat">
        <path
          d="M37.75 48.38L25.97 70.73L42.25 72.86L38.53 82.33L71.25 107.06L97.63 78.57L88.84 71.42L106.02 62.41L93.72 46.47"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
          fill="white"
        />
        <path
          d="M31.23 71.42L27.3 118.17"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinejoin="round"
        />
        <path
          d="M101.3 64.94L111.23 118.17"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 0, 0; 0, -2; -0, -2; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </g>
      <path
        d="M39.45 48.66L70.38 98.13L91.5 46.47L39.45 48.66Z"
        fill="black"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
      />
      <path
        d="M60.3 70.4C64.177 70.4 67.32 67.257 67.32 63.38C67.32 59.5029 64.177 56.36 60.3 56.36C56.423 56.36 53.28 59.5029 53.28 63.38C53.28 67.257 56.423 70.4 60.3 70.4Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.32 69.94C78.197 69.94 81.34 66.797 81.34 62.92C81.34 59.043 78.197 55.9 74.32 55.9C70.443 55.9 67.3 59.043 67.3 62.92C67.3 66.797 70.443 69.94 74.32 69.94Z"
        fill="white"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.95 67.97C64.7228 67.97 66.16 66.5328 66.16 64.76C66.16 62.9872 64.7228 61.55 62.95 61.55C61.1772 61.55 59.74 62.9872 59.74 64.76C59.74 66.5328 61.1772 67.97 62.95 67.97Z"
        fill="black"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 0, 0; -4, -3; -4, -3; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <path
        d="M76.84 67.37C78.6128 67.37 80.05 65.9328 80.05 64.16C80.05 62.3872 78.6128 60.95 76.84 60.95C75.0672 60.95 73.63 62.3872 73.63 64.16C73.63 65.9328 75.0672 67.37 76.84 67.37Z"
        fill="black"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 0, 0; -4, -3; -4, -3; 0, 0;"
          dur={duration}
          repeatCount="indefinite"
        />
      </path>
      <g className="hat">
        <path
          d="M42.8 32.24C42.8 32.24 28.41 34.61 25.56 39.24C24.56 40.91 36.48 53.61 73.05 50.5C107.43 47.57 100.3 36.03 98.05 35.38C85.49 31.78 87.7 35.98 87.7 35.98C87.7 35.98 86.7 10.13 77.62 10.77L70.2 13.51L58.55 11.16C58.55 11.16 52.19 10.47 49.16 12.16C45.62 14.16 42.4 33.16 42.4 33.16L42.8 32.24Z"
          fill="#FFD45C"
        />
        <path
          d="M42.8 32.24L42.15 36.91C42.15 36.91 68.68 48.24 87.71 36.28L87.52 32.67C87.52 32.67 64.87 41.74 42.8 32.24Z"
          fill="white"
        />
        <path
          d="M42.15 36.91C42.15 36.91 44.49 17.85 47.46 13.72C51.62 7.89 66.59 13.03 70.85 13.33"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M56.85 15C56.85 15 67.85 15.46 75.66 11.43C85 6.64 87.28 25.92 87.71 36"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M42.8 32.24C42.8 32.24 20.63 35.91 26.44 41.78C31.92 47.33 51.2 51.58 67.66 50.78C96.66 49.46 103.06 41.67 99.56 36.64C99.56 36.64 96.4 32.71 87.91 33.97"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M87.52 32.67C63 40.55 42.8 32.24 42.8 32.24"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M42.15 36.91C42.15 36.91 59.66 46.3 84.23 38.43"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 50, 30; 0, 50, 30; -4, 50, 30; -4, 50, 30; 0, 50, 30;"
          dur={duration}
          repeatCount="indefinite"
        />
      </g>
    </svg>
  );
}
