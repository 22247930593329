import React from "react";

import "./styles/globals.scss";
import { Header } from "./components/Header/Header";
import { Footer } from "./components/Footer/Footer";
import { MainPromo } from "./components/MainPromo/MainPromo";
import { HomeFeatures } from "./components/HomeFeatures/HomeFeatures";
import { Statistics } from "./components/Statistics/Statistics";
import { HomeReviews } from "./components/HomeReviews/HomeReviews";
import { HomeAdvantages } from "./components/HomeAdvantages/HomeAdvantages";
import { StartTrial } from "./components/StartTrial/StartTrial";

function App() {
  return (
    <div className="App">
      <Header />
      <MainPromo />
      <HomeFeatures
        trustBoxParams={{
          trustScore: 4.4,
          trustScoreText: "Excellent",
          reviewsQuantity: 520,
        }}
      />
      <Statistics />
      <HomeReviews />
      <HomeAdvantages />
      <StartTrial />
      <Footer />
    </div>
  );
}

export default App;
