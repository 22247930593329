import React from 'react';
import { SvgIcon } from '@mui/material';
import cn from 'classnames';

import styles from './OkexLogo.module.scss';

type Props = {
  size: 'large' | 'medium' | 'small';
  isColorful?: boolean;
};

function OkexLogo({ size }: Props) {
  return (
    <SvgIcon className={cn(styles.root, styles[size])} viewBox="0 0 85 24">
      <path
        d="M24.9301 0H0.566521C0.416268 0 0.272162 0.0561439 0.16596 0.156216C0.059645 0.256181 0 0.391823 0 0.533359V23.4662C0 23.6076 0.059645 23.7433 0.16596 23.8432C0.272162 23.9433 0.416268 23.9995 0.566521 23.9995H24.9301C25.0805 23.9995 25.2246 23.9433 25.3308 23.8432C25.4371 23.7433 25.4968 23.6076 25.4968 23.4662V0.533359C25.4968 0.391823 25.4371 0.256181 25.3308 0.156216C25.2246 0.0561439 25.0805 0 24.9301 0ZM16.9978 15.4664C16.9978 15.6078 16.9382 15.7434 16.8319 15.8435C16.7257 15.9435 16.5815 15.9997 16.4313 15.9997H9.06549C8.91523 15.9997 8.77113 15.9435 8.66481 15.8435C8.55861 15.7434 8.49896 15.6078 8.49896 15.4664V8.53311C8.49896 8.39168 8.55861 8.25603 8.66481 8.15607C8.77113 8.056 8.91523 7.99986 9.06549 7.99986H16.4313C16.5815 7.99986 16.7257 8.056 16.8319 8.15607C16.9382 8.25603 16.9978 8.39168 16.9978 8.53311V15.4664Z"
        fill="black"
      />
      <path
        d="M75.9379 8.00073H68.5722C68.2593 8.00073 68.0056 8.23945 68.0056 8.53398V15.4672C68.0056 15.7618 68.2593 16.0006 68.5722 16.0006H75.9379C76.2509 16.0006 76.5046 15.7618 76.5046 15.4672V8.53398C76.5046 8.23945 76.2509 8.00073 75.9379 8.00073Z"
        fill="black"
      />
      <path
        d="M67.4356 0.000640869H60.0699C59.757 0.000640869 59.5032 0.239356 59.5032 0.534V7.46714C59.5032 7.76167 59.757 8.0005 60.0699 8.0005H67.4356C67.7486 8.0005 68.0022 7.76167 68.0022 7.46714V0.534C68.0022 0.239356 67.7486 0.000640869 67.4356 0.000640869Z"
        fill="black"
      />
      <path
        d="M84.4335 0.000640869H77.0677C76.7548 0.000640869 76.501 0.239356 76.501 0.534V7.46714C76.501 7.76167 76.7548 8.0005 77.0677 8.0005H84.4335C84.7464 8.0005 85 7.76167 85 7.46714V0.534C85 0.239356 84.7464 0.000640869 84.4335 0.000640869Z"
        fill="black"
      />
      <path
        d="M67.4356 16.0002H60.0699C59.757 16.0002 59.5032 16.2389 59.5032 16.5334V23.4667C59.5032 23.7612 59.757 24 60.0699 24H67.4356C67.7486 24 68.0022 23.7612 68.0022 23.4667V16.5334C68.0022 16.2389 67.7486 16.0002 67.4356 16.0002Z"
        fill="black"
      />
      <path
        d="M84.4335 16.0002H77.0677C76.7548 16.0002 76.501 16.2389 76.501 16.5334V23.4667C76.501 23.7612 76.7548 24 77.0677 24H84.4335C84.7464 24 85 23.7612 85 23.4667V16.5334C85 16.2389 84.7464 16.0002 84.4335 16.0002Z"
        fill="black"
      />
      <path
        d="M54.6765 0.000640869H47.3108C46.9979 0.000640869 46.7441 0.239356 46.7441 0.534V7.46714C46.7441 7.76167 46.9979 8.0005 47.3108 8.0005H54.6765C54.9894 8.0005 55.2431 7.76167 55.2431 7.46714V0.534C55.2431 0.239356 54.9894 0.000640869 54.6765 0.000640869Z"
        fill="black"
      />
      <path
        d="M54.6765 16.0002H47.3108C46.9979 16.0002 46.7441 16.2389 46.7441 16.5334V23.4667C46.7441 23.7612 46.9979 24 47.3108 24H54.6765C54.9894 24 55.2431 23.7612 55.2431 23.4667V16.5334C55.2431 16.2389 54.9894 16.0002 54.6765 16.0002Z"
        fill="black"
      />
      <path
        d="M46.7441 8.52711C46.7441 8.38568 46.6844 8.25003 46.5782 8.15007C46.4719 8.05 46.3278 7.99386 46.1775 7.99386H38.2452V0.533359C38.2452 0.391823 38.1854 0.256181 38.0792 0.156216C37.9729 0.0561439 37.8288 0 37.6785 0H30.3129C30.1625 0 30.0184 0.0561439 29.9122 0.156216C29.8059 0.256181 29.7462 0.391823 29.7462 0.533359V23.4542C29.7462 23.5956 29.8059 23.7313 29.9122 23.8312C30.0184 23.9313 30.1625 23.9875 30.3129 23.9875H37.6785C37.8288 23.9875 37.9729 23.9313 38.0792 23.8312C38.1854 23.7313 38.2452 23.5956 38.2452 23.4542V15.9937H46.1775C46.3278 15.9937 46.4719 15.9375 46.5782 15.8375C46.6844 15.7374 46.7441 15.6018 46.7441 15.4604V8.52711Z"
        fill="black"
      />
    </SvgIcon>
  );
}

export { OkexLogo };
