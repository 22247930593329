import React from 'react';
import { devices } from '../../../styles/devices';


export type DoodleType = {
  className: string;
  Doodle: React.JSXElementConstructor<{ strokeWidth?: number }>;
};

type Props = {
  doodles: DoodleType[];
};

export function Doodles({ doodles }: Props) {
  return (
    <>
      {doodles.map(({ className, Doodle }: DoodleType) => {
        return (
          <div key={className} className={className}>
            <div className={devices.mobileTablet}>
              <Doodle />
            </div>
            <div className={devices.desktop}>
              <Doodle strokeWidth={2} />
            </div>
          </div>
        );
      })}
    </>
  );
}
