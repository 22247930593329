import React from 'react';
import cn from 'classnames';
import MuiButton, { ButtonProps } from '@mui/material/Button';

import { DeviceClass, devices } from '../../styles/devices';
import { FakeLink } from '../FakeLink/FakeLink';
import { ButtonColor, ButtonSize, ButtonVariant } from '../../styles/theme';

import styles from './Button.module.scss';

type BtnSize = ButtonSize.X_SMALL | ButtonSize.SMALL | ButtonSize.MEDIUM;

interface Props extends Omit<ButtonProps, 'size'> {
  testId?: string;
  uppercase?: boolean;
  target?: string;
  size?:
    | BtnSize
    | {
        mobile: BtnSize;
        tablet: BtnSize;
        desktop: BtnSize;
      };
  color?: ButtonColor.PRIMARY | ButtonColor.SECONDARY;
  variant?: ButtonVariant.CONTAINED | ButtonVariant.OUTLINED;
}

export const Button = ({
  testId,
  children,
  uppercase,
  size,
  href,
  ...rest
}: Props): JSX.Element => {
  if (href) {
    return (
      <FakeLink>
        {renderButton()}
      </FakeLink>
    );
  }
  return renderButton();

  function renderButton() {
    if (size && typeof size === 'object') {
      return (
        <>
          {renderMuiButton(size.mobile, devices.mobile)}
          {renderMuiButton(size.tablet, devices.tablet)}
          {renderMuiButton(size.desktop, devices.desktop)}
        </>
      );
    }

    return renderMuiButton(size);
  }

  function renderMuiButton(size?: BtnSize, deviceClass?: DeviceClass) {
    return (
      <MuiButton
        data-test={testId}
        {...rest}
        size={size}
        href={href}
        classes={{
          root: cn(
            styles.root,
            {
              [styles.uppercase]: uppercase,
              [styles.containedSizeXSmall]: size === ButtonSize.X_SMALL,
            },
            deviceClass
          ),
          contained: styles.contained,
          containedPrimary: styles.containedPrimary,
          containedSecondary: styles.containedSecondary,
          containedSizeSmall: styles.containedSizeSmall,
          containedSizeMedium: styles.containedSizeMedium,
          outlined: styles.outlined,
          outlinedPrimary: styles.outlinedPrimary,
          outlinedSecondary: styles.outlinedSecondary,
          outlinedSizeSmall: styles.outlinedSizeSmall,
          outlinedSizeMedium: styles.outlinedSizeMedium,
          fullWidth: styles.fullWidth,
        }}
      >
        <span className={styles.content}>{children}</span>
      </MuiButton>
    );
  }
};
