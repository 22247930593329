import React from 'react';
import { SvgIcon } from '@mui/material';

import stylesConstants from '../../styles/constants.module.scss';

type Props = {
  thin?: boolean;
  className?: string;
};

function MinusIcon({ className, thin }: Props) {
  return (
    <SvgIcon className={className} viewBox="0 0 27 27">
      <path
        d="M2 13.5H25"
        stroke={stylesConstants.BLUE_1}
        strokeWidth={thin ? 2 : 4}
        strokeLinecap="round"
      />
    </SvgIcon>
  );
}

export { MinusIcon };
