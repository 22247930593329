import { createTheme, Theme } from '@mui/material';

import stylesConstants from './constants.module.scss';

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonSize {
  X_SMALL = 'x_small',
  SMALL = 'small',
  MEDIUM = 'medium',
}

export enum ButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    [ButtonColor.PRIMARY]: true;
    [ButtonColor.SECONDARY]: true;
  }
  interface ButtonPropsSizeOverrides {
    [ButtonSize.X_SMALL]: true;
    [ButtonSize.SMALL]: true;
    [ButtonSize.MEDIUM]: true;
  }
  interface ButtonPropsVariantOverrides {
    [ButtonVariant.CONTAINED]: true;
    [ButtonVariant.OUTLINED]: true;
  }
}

export const getTheme = (): Theme =>
  createTheme({
    typography: {
      fontFamily: stylesConstants.APP_FONT_FAMILY,
    },
    palette: {
      primary: {
        main: stylesConstants.BLUE_1,
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            boxShadow: 'none',
            textTransform: 'inherit',
          },
          text: {
            color: stylesConstants.BLACK,
            fontSize: 18,
            lineHeight: '22px',
          },
          contained: {
            color: stylesConstants.WHITE,
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
        },
      },
    },
    zIndex: {
      drawer: 1502,
      modal: 1503,
    },
  });
