import React, { useEffect, useRef } from 'react';

type Props = {
  strokeWidth?: number;
  animate?: boolean;
  size?: number;
  clickMode?: boolean;
};

export function DoodleRobot({
  strokeWidth = 1,
  animate = true,
  size = 140,
  clickMode = false,
}: Props) {
  const imageRef = useRef<SVGSVGElement>(null);
  const begin = clickMode ? 'robot.click' : 0;
  const duration = '2s';
  const repeatCount = clickMode ? 1 : 'indefinite';

  useEffect(() => {
    imageRef.current?.[animate ? 'unpauseAnimations' : 'pauseAnimations']();
  }, [animate]);

  return (
    <svg
      id={clickMode ? 'robot' : ''}
      ref={imageRef}
      width={size}
      height={size}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.02 49.29L20.21 104.58L94.44 111.8L99.55 55.6L25.02 49.29Z"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.33 91.76L10.6 90.47L13.11 66.03L23.47 67.11"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g className="right-horn">
        <path
          d="M71.49 53.23L86.35 33.03L92.3 36.85L83.53 54.24"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="right-antenna"
        />
        <path
          d="M93.36 35.98C97.7341 35.98 101.28 32.4341 101.28 28.06C101.28 23.6859 97.7341 20.14 93.36 20.14C88.9859 20.14 85.44 23.6859 85.44 28.06C85.44 32.4341 88.9859 35.98 93.36 35.98Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#FF5E8E"
          className="right-ball"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5 0; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </g>
      <g className="left-horn">
        <path
          d="M48.13 51.25L44.86 35.01L49.68 33.03L62.71 52.48"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="left-antenna"
        />
        <path
          d="M44.08 34.61C48.7579 34.61 52.55 30.8179 52.55 26.14C52.55 21.4622 48.7579 17.67 44.08 17.67C39.4022 17.67 35.61 21.4622 35.61 26.14C35.61 30.8179 39.4022 34.61 44.08 34.61Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#FF5E8E"
          className="left-ball"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -3 -1; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </g>
      <path
        d="M57.13 18.5599L62.71 25.5299L72.29 12.6899L73.79 27.5799L80.48 23.6199"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="discharge"
      >
        <animate
          attributeName="d"
          values="M57.13 18.5599L62.71 25.5299L72.29 12.6899L73.79 27.5799L80.48 23.6199; M52.5 21.5L61 12.5L68 31.5L76.5 17.5L86 23.5799; M57.13 18.5599L62.71 25.5299L72.29 12.6899L73.79 27.5799L80.48 23.6199"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M42 88.26C47.8266 88.26 52.55 83.5366 52.55 77.71C52.55 71.8834 47.8266 67.16 42 67.16C36.1734 67.16 31.45 71.8834 31.45 77.71C31.45 83.5366 36.1734 88.26 42 88.26Z"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="left-eye"
      >
        <animate
          attributeName="d"
          values="M42 88.26C47.8266 88.26 52.55 83.5366 52.55 77.71C52.55 71.8834 47.8266 67.16 42 67.16C36.1734 67.16 31.45 71.8834 31.45 77.71C31.45 83.5366 36.1734 88.26 42 88.26Z; M42 86C48.0751 86 53 81.7467 53 76.5C53 71.2533 48.0751 67 42 67C35.9249 67 31 71.2533 31 76.5C31 81.7467 35.9249 86 42 86Z; M42 88.26C47.8266 88.26 52.55 83.5366 52.55 77.71C52.55 71.8834 47.8266 67.16 42 67.16C36.1734 67.16 31.45 71.8834 31.45 77.71C31.45 83.5366 36.1734 88.26 42 88.26Z;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M79.35 91.09C85.1766 91.09 89.9 86.3666 89.9 80.54C89.9 74.7134 85.1766 69.99 79.35 69.99C73.5234 69.99 68.8 74.7134 68.8 80.54C68.8 86.3666 73.5234 91.09 79.35 91.09Z"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        className="right-eye"
      >
        <animate
          attributeName="d"
          values="M79.35 91.09C85.1766 91.09 89.9 86.3666 89.9 80.54C89.9 74.7134 85.1766 69.99 79.35 69.99C73.5234 69.99 68.8 74.7134 68.8 80.54C68.8 86.3666 73.5234 91.09 79.35 91.09Z; M79.5 89C85.299 89 90 84.7467 90 79.5C90 74.2533 85.299 70 79.5 70C73.701 70 69 74.2533 69 79.5C69 84.7467 73.701 89 79.5 89Z; M79.35 91.09C85.1766 91.09 89.9 86.3666 89.9 80.54C89.9 74.7134 85.1766 69.99 79.35 69.99C73.5234 69.99 68.8 74.7134 68.8 80.54C68.8 86.3666 73.5234 91.09 79.35 91.09Z;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M37.53 81.72C40.3522 81.72 42.64 79.4322 42.64 76.61C42.64 73.7878 40.3522 71.5 37.53 71.5C34.7078 71.5 32.42 73.7878 32.42 76.61C32.42 79.4322 34.7078 81.72 37.53 81.72Z"
        fill="black"
        className="left-apple-of-eye"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 0 -1; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M75.33 85.39C78.2737 85.39 80.66 83.0037 80.66 80.06C80.66 77.1163 78.2737 74.73 75.33 74.73C72.3863 74.73 70 77.1163 70 80.06C70 83.0037 72.3863 85.39 75.33 85.39Z"
        fill="black"
        className="right-apple-of-eye"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 0 -1; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M99.55 55.6L118.98 68.57L114.24 123.08L94.44 111.8"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.21 104.58L34.45 115.16L114.24 123.08"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M109.9 76.61L122.65 77.89L119.67 102.34L107.35 101.26"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="white"
      />
      <path
        d="M56.17 89.47C56.17 89.47 53.44 95.72 57.52 96.26C61.6 96.8 61.18 89.47 61.18 89.47"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="white"
        className="nose"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 58, 93; 22, 58, 93; 0, 58, 93;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
    </svg>
  );
}
