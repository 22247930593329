import React, { useRef, useEffect } from 'react';

import {
  LEFT_EYES_COORDS,
  LEFT_EYES_WITH_EYEBROWS_COORDS,
  RIGHT_EYES_COORDS,
  RIGHT_EYES_WITH_EYEBROWS_COORDS,
  MAX_TRANSLATE_VALUE,
  MAX_DIAGONAL_TRANSLATE_VALUE,
} from './eyes.constants';

type Props = {
  strokeWidth?: number;
  isFollowing?: boolean;
  flipHorizontal?: boolean;
  viewDirection?: 'left' | 'right' | 'middle';
  withEyeBrows?: boolean;
};

export function Eyes({
  strokeWidth = 1,
  isFollowing = false,
  flipHorizontal = false,
  viewDirection = 'middle',
  withEyeBrows = false,
}: Props) {
  const doodleRef = useRef<SVGSVGElement>(null);
  const leftEyeRef = useRef<SVGCircleElement>(null);
  const rightEyeRef = useRef<SVGCircleElement>(null);
  const leftPupilOfEyeRef = useRef<SVGCircleElement>(null);
  const rightPupilOfEyeRef = useRef<SVGCircleElement>(null);

  const style: React.CSSProperties = {};

  if (flipHorizontal) {
    style.transform = 'matrix(-1, 0, 0, 1, 0, 0)';
  }
  const leftEyeCoords = withEyeBrows ? LEFT_EYES_WITH_EYEBROWS_COORDS : LEFT_EYES_COORDS;
  const rightEyeCoords = withEyeBrows ? RIGHT_EYES_WITH_EYEBROWS_COORDS : RIGHT_EYES_COORDS;

  useEffect(() => {
    if (isFollowing) {
      const onMouseMove = (evt: MouseEvent) => {
        if (
          doodleRef?.current &&
          leftEyeRef.current &&
          rightEyeRef.current &&
          leftPupilOfEyeRef.current &&
          rightPupilOfEyeRef.current
        ) {
          const leftEyeElement: SVGCircleElement = leftEyeRef.current;
          const rightEyeElement: SVGCircleElement = rightEyeRef.current;

          const leftPupilOfEyeElement: ElementCSSInlineStyle = leftPupilOfEyeRef.current;
          const rightPupilOfEyeElement: ElementCSSInlineStyle = rightPupilOfEyeRef.current;

          const eyesRect: DOMRect = doodleRef.current.getBoundingClientRect();

          const left = leftEyeElement.getBoundingClientRect().left;
          const right = rightEyeElement.getBoundingClientRect().right;

          let x = 0;
          let y = 0;
          if (evt.x < left) {
            x = ((evt.x - left) / left) * MAX_TRANSLATE_VALUE;
          } else if (evt.x > right) {
            x = ((evt.x - right) / (window.innerWidth - right)) * MAX_TRANSLATE_VALUE;
          }

          if (evt.y < eyesRect.y) {
            y = ((evt.y - eyesRect.y) / eyesRect.y) * MAX_TRANSLATE_VALUE;
          } else {
            y = ((evt.y - eyesRect.y) / (window.innerHeight - eyesRect.y)) * MAX_TRANSLATE_VALUE;
          }

          if (
            Math.abs(x) > MAX_DIAGONAL_TRANSLATE_VALUE &&
            Math.abs(y) > MAX_DIAGONAL_TRANSLATE_VALUE
          ) {
            x =
              x > MAX_DIAGONAL_TRANSLATE_VALUE
                ? MAX_DIAGONAL_TRANSLATE_VALUE
                : -MAX_DIAGONAL_TRANSLATE_VALUE;
            y =
              y > MAX_DIAGONAL_TRANSLATE_VALUE
                ? MAX_DIAGONAL_TRANSLATE_VALUE
                : -MAX_DIAGONAL_TRANSLATE_VALUE;
          }

          if (flipHorizontal) {
            x = -x;
          }

          leftPupilOfEyeElement.style.transform = `translateY(${y}px) translateX(${x}px)`;
          rightPupilOfEyeElement.style.transform = `translateY(${y}px) translateX(${x}px)`;
        }
      };

      if (typeof window !== 'undefined') {
        document.addEventListener('mousemove', onMouseMove);
      }

      return () => {
        document.removeEventListener('mousemove', onMouseMove);
      };
    }
  }, [flipHorizontal, isFollowing]);

  return (
    <svg
      ref={doodleRef}
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle
        ref={leftEyeRef}
        cx={leftEyeCoords.middle.X}
        cy={leftEyeCoords.middle.Y}
        r={12.77}
        stroke="black"
        strokeWidth={strokeWidth}
        fill="white"
      />
      <circle
        ref={rightEyeRef}
        cx={rightEyeCoords.middle.X}
        cy={rightEyeCoords.middle.Y}
        r={12.77}
        stroke="black"
        strokeWidth={strokeWidth}
        fill="white"
      />
      <circle
        ref={leftPupilOfEyeRef}
        cx={leftEyeCoords[viewDirection].X}
        cy={leftEyeCoords[viewDirection].Y}
        r={6.43}
        fill="black"
      />
      <circle
        ref={rightPupilOfEyeRef}
        cx={rightEyeCoords[viewDirection].X}
        cy={rightEyeCoords[viewDirection].Y}
        r={6.43}
        fill="black"
      />
      {withEyeBrows && (
        <>
          <path
            d="M6.33984 33.8704C6.95984 32.6704 11.6698 11.9704 29.3398 28.1204"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.6094 27.0997C33.9994 25.7997 34.5794 4.57974 55.0894 16.9997"
            stroke="black"
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      )}
    </svg>
  );
}
