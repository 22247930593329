import React from 'react';

type Props = {
  strokeWidth?: number;
  size?: number;
  clickMode?: boolean;
};

export function DoodleBitcoin({ strokeWidth = 1, size = 140, clickMode = false }: Props) {
  const begin = clickMode ? 'bitcoin.click' : 0;
  const duration = '2s';
  const repeatCount = clickMode ? 1 : 'indefinite';

  return (
    <svg
      id={clickMode ? 'bitcoin' : ''}
      width={size}
      height={size}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="left-leg">
        <path
          d="M57.62 98.41L54.17 114.31L52.04 125.71H20.71V121.75L37.96 114.77V89.12"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="white"
        />
        <path
          d="M37.96 103.85L55.55 107.93"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.96 109.22L54.5 112.78"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M34.97 115.98L38.69 120.18"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M32.06 117.21L35.78 121.41"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.88 118.7L32.6 121.77"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -5, -5; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
          additive="sum"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 39.5, 107; 15, 39.5, 107; 0, 39.5, 107;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
          additive="sum"
        />
      </g>
      <g className="right-leg">
        <path
          d="M89.11 88.62V115.29L106.37 122.29V126.29H75L72.88 114.89L69.25 98C69.25 98 78.32 97.47 89.11 88.62Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="white"
        />
        <path
          d="M89.11 104.37L71.53 108.46"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.11 109.74L72.58 113.3"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.71 117.77L90.99 121.98"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M97.61 119.01L93.89 123.21"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M100.33 120.13L97.61 123.21"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 6, -5; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
          additive="sum"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 87.5, 108; -15, 87.5, 108; 0, 87.5, 108;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
          additive="sum"
        />
      </g>
      <path
        d="M63.25 24.1C90.33 24.1 105.92 40.74 105.92 61.26C105.92 81.78 91.2 98.41 64.83 98.41"
        fill="white"
      />
      <path
        d="M63.25 98.41C84.3638 98.41 101.48 81.7774 101.48 61.26C101.48 40.7426 84.3638 24.11 63.25 24.11C42.1362 24.11 25.02 40.7426 25.02 61.26C25.02 81.7774 42.1362 98.41 63.25 98.41Z"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="white"
      />
      <path
        d="M63.25 24.1C90.33 24.1 105.92 40.74 105.92 61.26C105.92 81.78 91.2 98.41 64.83 98.41"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g className="coin">
        <path
          d="M82.88 68.94C81.68 60.63 77 58.87 74 58.7C74 58.7 79.65 56.08 78.31 48.39C76.97 40.7 69.46 38.15 65.7 39.26L64.21 34.88L57.33 36.33L58.47 40.46L53.36 41.46L52.23 37.21L45.65 38.63L46.79 42.76L41.79 43.76L43 50.54L46.63 49.1L52.44 78.1L48.02 78.52L49.29 85.09L54.85 84.41L56.42 88.41L61.85 87.41C61.85 87.41 60.85 83.41 61.5 83.15C62.15 82.89 66 82.33 66 82.33L67.71 86.62L74 84.13L72.82 81.13C72.82 81.13 84.08 77.26 82.88 68.94ZM56.45 58.7L54.17 49L65.07 46.37C65.7137 46.2004 66.3847 46.161 67.0438 46.2541C67.7029 46.3472 68.3367 46.571 68.9082 46.9123C69.4797 47.2537 69.9772 47.7057 70.3717 48.2418C70.7661 48.778 71.0496 49.3875 71.2054 50.0346C71.3612 50.6818 71.3862 51.3535 71.2789 52.0105C71.1717 52.6674 70.9344 53.2963 70.5809 53.8603C70.2274 54.4243 69.7648 54.9121 69.2203 55.2949C68.6758 55.6778 68.0603 55.9481 67.41 56.09L56.45 58.7ZM71.85 73.57L60.07 76.42L57.61 66L69.32 63.17C70.0055 63.0039 70.717 62.9744 71.4139 63.0833C72.1108 63.1921 72.7794 63.4372 73.3816 63.8044C73.9838 64.1717 74.5077 64.6539 74.9236 65.2237C75.3394 65.7934 75.6389 66.4395 75.805 67.125C75.9711 67.8105 76.0006 68.522 75.8917 69.2189C75.7829 69.9158 75.5378 70.5844 75.1706 71.1866C74.8033 71.7888 74.3211 72.3128 73.7513 72.7286C73.1816 73.1444 72.5355 73.4439 71.85 73.61V73.57Z"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="#FED55C"
        />
        <path
          d="M92.33 37.14H96.88"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <animate
            attributeName="d"
            values="M92.33 37.14H96.88; M92 37.14H96; M92.33 37.14H96.88;"
            dur={duration}
            repeatCount={repeatCount}
            begin={begin}
          />
        </path>
        <path
          d="M96.88 43.57L101 43.55"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <animate
            attributeName="d"
            values="M96.88 43.57L101 43.55; M96.88 43.57L101 43.55; M96.88 43.57L101 43.55;"
            dur={duration}
            repeatCount={repeatCount}
            begin={begin}
          />
        </path>
        <path
          d="M100.07 51.23L104 51"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <animate
            attributeName="d"
            values="M100.07 51.23L104 51; M100.07 51.23L104 51; M100.07 51.23L104 51;"
            dur={duration}
            repeatCount={repeatCount}
            begin={begin}
          />
        </path>
        <path
          d="M101.42 59.08H105.86"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M100.94 67.54L105.4 67.83"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M98.27 76.19L102.79 76.73"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M94.25 83.39L98.47 83.96"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88 90H91.6"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <animate
            attributeName="d"
            values="M88 90H91.6; M88 90H92.6; M88 90H91.6;"
            dur={duration}
            repeatCount={repeatCount}
            begin={begin}
          />
        </path>
        <path
          d="M82.84 34.88C82.84 34.88 96.64 46.27 96.84 64.67"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.88 63C29.88 63 30.83 75.77 38 82.75"
          stroke="black"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0, 63, 61; -7, 63, 61; 0, 63, 61;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </g>
      <path
        d="M21.63 20.73L31.21 27.01"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -4 -3; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M32.37 16.18L35.76 22.25"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; -4 -6; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M105.4 16.18L97.6 27.01"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 5 -6; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
      <path
        d="M109.19 27.45L101.42 30.2"
        stroke="black"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <animateTransform
          attributeName="transform"
          type="translate"
          values="0, 0; 7 -4; 0, 0;"
          dur={duration}
          repeatCount={repeatCount}
          begin={begin}
        />
      </path>
    </svg>
  );
}
