import React from 'react';


import styles from './HomeAdvantages.module.scss';
import { Container } from '../Container/Container';
import { CheckIcon } from '../icons';

export const HomeAdvantages = () => {

  const advantages = [
    {
      title: 'Your funds are secure',
      description: 'Bitsgap doesn’t have access to your exchange account and cannot withdraw your funds.',
    },
    {
      title: 'API-key is all you need',
      description: 'Connect your exchange account using a secure API connection and get started. It’s easy.',
    },
    {
      title: 'Fast trading servers',
      description: 'Our servers are located close to popular exchanges to ensure stable and fast order execution.',
    },
  ];

  return (
    <section className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.generalInfo}>
            <h2 className={styles.title}>
              Secure<span className={styles.colorBlue}>.</span><br/> Easy<span className={styles.colorBlue}>.</span><br/> Fast<span className={styles.colorBlue}>.</span><br/>
            </h2>
            <p className={styles.description}>Bitsgap has no access to your funds. And will never have.</p><p className={styles.description}>Our platform executes trades, while keeping all information entirely confidential.</p>
          </div>
          <div className={styles.advantagesList}>
            {advantages.map(({ title, description }) => (
              <div key={title} className={styles.item}>
                <CheckIcon className={styles.checkIcon} />
                <div>
                  <h4 className={styles.itemTitle}>{title}</h4>
                  <p className={styles.itemDescription}>{description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};
